// service auth API
const BASE_URL_AUTH = process.env.REACT_APP_BASE_URL_API_AUTH;
export const api_login = BASE_URL_AUTH + 'sso/login';
export const api_refresh = BASE_URL_AUTH + 'sso/refresh';
export const api_list_web = 'https://my.umkt.ac.id/manajemen_applikasi/list-web';

// service pegawai API //
export const token_temp = process.env.REACT_APP_TOKEN_TEMP;
const BASE_URL_PEGAWAI = process.env.REACT_APP_API_PEGAWAI_NO_TOKEN;
export const api_pegawai = BASE_URL_PEGAWAI;

/// service keuangan API ///
const BASE_URL_KEUANGAN = process.env.REACT_APP_BASE_URL_API_KEUANGAN;
// const BASE_URL_KEUANGAN = process.env.REACT_APP_BASE_URL_API_LOCAL;
// Operator
export const api_kode_anggaran = 'mata-anggaran';
export const api_periode = 'periode';
export const api_list_user_group = 'list-user-group';
export const api_add_user_group = 'add-user-group/';
export const api_delete_user_group = 'delete-user-group/';
export const api_get_user_group = 'get-user-group';
export const api_list_rekening = 'admin-rekening';
export const api_list_rekening_v2 = 'admin-rekening-v2';
export const api_list_rekening_all = 'rekening-all';
export const api_sinkron_rekening = 'rekening-ambil-semua-lembaga';
export const api_list_lembaga_anggaran = 'admin-anggaran-lembaga';
export const api_anggaran_by_lembaga = 'admin-anggaran/';
export const api_anggaran_by_lembaga_v2 = 'admin-anggaran-periode/';
export const api_peralihan_anggaran_admin = 'admin-anggaran-peralihan';

// End Operator

// Unit
export const api_anggaran = 'anggaran/';
export const api_anggaran_v2 = 'anggaran-periode/';
export const api_anggaran_periode = 'anggaran-periode-now/';
export const api_peralihan_anggaran = 'anggaran-peralihan';

export const api_sinkron_apple = 'ambil-anggaran-apple/';
export const api_sinkron_kegiatan_apple = 'ambil-kegiatan-apple/';

export const api_trx_unit_list = 'transaksi-unit/';
export const api_trx_unit_list_periode = 'transaksi-unit-periode/';
export const api_detail_trx_unit = 'detail-transaksi/';

export const api_trx_unit_nota_list = 'transaksi-unit-pengadaan/';

export const api_rekening = 'rekening/';
export const api_rekening_v2 = 'rekening-v2/';

export const api_rekening_delegasi = 'rekening-delegasi/';
export const api_rekening_delegasi_v2 = 'rekening-delegasi-v2/';

export const api_homebase = 'homebase-delegasi-pimpinan';

export const api_log_rekening = 'log-rekening-unit/';
export const api_log_rekening_v2 = 'log-rekening-unit-v2/';
export const api_export_excel = BASE_URL_KEUANGAN + '/export-excel/';
export const api_export_excel_v2 = BASE_URL_KEUANGAN + '/export-excel-v2/';
export const api_export_excel_v3 = BASE_URL_KEUANGAN + 'export-excel-v3/';
export const api_export_excel_v4 = BASE_URL_KEUANGAN + 'export-excel-v4/';

export const api_export_rab = BASE_URL_KEUANGAN + 'export-excel-rab/'
export const api_data_export_rab = 'data-excel-rab/'
// End Unit

// Keuangan
export const api_rab = 'rab';
export const api_rab_v2 = 'rab-periode';
export const api_mata_anggaran = 'transaksi-mata-anggaran';
export const api_mata_anggaran_detail = 'transaksi-mata-anggaran-detail';
export const api_mata_anggaran_v2 = 'transaksi-mata-anggaran-v2';
export const api_unit_kerja = 'kode-unit-kerja';
export const api_trx_unit_verifikasi = 'transaksi-unit-verifikasi/list/'
export const api_trx_unit_verifikasi_detail = 'transaksi-unit-verifikasi/'
export const api_list_rekening_lembaga = 'admin-list-lembaga'

export const api_trx_unit_edit = 'redit-trx-unit/'
export const api_trx_unit_acc = 'accedit-trx-unit/'
export const api_trx_agr_edit = 'redit-trx-agr/'
export const api_trx_agr_acc = 'accedit-trx-agr/'

export const api_trx_unit_edit_list = 'redit-trx-unit-list'
export const api_trx_agr_edit_list = 'redit-trx-agr-list'
// End Keuangan

//Checker and Approve and Reject
export const api_trx_unit_list_selesai = 'transaksi-unit-selesai';
export const api_trx_unit_list_checked = 'transaksi-unit-checked';

export const api_trx_unit_checker = 'transaksi-unit/update-checker/';
export const api_trx_unit_tolak_checker = 'transaksi-unit/tolak-checker/';

export const api_trx_unit_approve = 'transaksi-unit/update-approve/';
export const api_trx_unit_tolak_approve = 'transaksi-unit/tolak-approve/';

export const api_mata_anggaran_list_selesai = 'mata-anggaran-selesai';
export const api_mata_anggaran_list_checked = 'mata-anggaran-checked';

export const api_mata_anggaran_checker = 'mata-anggaran/update-checker/';
export const api_mata_anggaran_tolak_checker = 'mata-anggaran/tolak-checker/';

export const api_mata_anggaran_approve = 'mata-anggaran/update-approve/';
export const api_mata_anggaran_tolak_approve = 'mata-anggaran/tolak-approve/';

export const api_anggaran_detail = 'anggaran-detail/';
// End Checker and Approve


/// service sarpras mongo API ///
const BASE_URL_SARPRAS = process.env.REACT_APP_BASE_URL_API_SARPRAS;
export const api_aset_inventaris = 'depresiasi-isi/10/';
export const api_aset_depresiasi = 'depresiasi-kosong/10/';
export const api_detail_inventaris = 'detail';
export const api_depresiasi_multi_inventaris = 'depresiasi-multiple';
export const api_search_inventaris = 'search-depresiasi-isi/10/';
export const api_search_depresiasi = 'search-depresiasi-kosong/10/';
// End Sarpras mongo


/// service sarpras django API ///
const BASE_URL_SARPRAS_DJANGO = process.env.REACT_APP_BASE_URL_API_SARPRAS_DJANGO;
export const api_ruangan_list = 'aset/api-ruangan';
// End sarpras django


