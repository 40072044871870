import Moment from "moment/moment";
import { Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";

import { FormatRupiah } from "@arismun/format-rupiah";
import { useNavigate, useParams } from "react-router-dom";

import { detailStyle, swalCustom } from '../../../components/CustomStyle';
import { GetTransaksiUnitDetail, UpdateTransaksiUnit } from "../../../services/KeuanganService";

const TrxUnitUploadBukti = () => {
    let { id } = useParams();
    let navigate = useNavigate();

    let [detail, setDetail] = useState({});

    let cookies = new Cookies();
    let homebase = cookies.get('homebase_kode');
    let [rekTujuan, setRekTujuan] = useState('');

    const getDetail = (id) => {
        GetTransaksiUnitDetail(homebase, id).then((response) => {
            setDetail(response.data);
            setRekTujuan(response.data.nama_rekening_tujuan);

        }).catch(() => console.log('error get_trx_unit_detail'));
    }

    useEffect(() => {
        getDetail(id);
    }, [id])

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('anggaran_id', detail.anggaran.id);
        formData.append('keterangan', detail.keterangan);
        formData.append('status_trx', detail.status_trx);
        formData.append('tanggal', detail.tanggal);

        if (rekTujuan.toLowerCase().includes('pengadaan') !== true) {
            if (event.target.bukti != undefined) {
                formData.append('bukti', event.target.bukti.files[0]);
            }
        }

        if (event.target.bukti_tf != undefined) {
            formData.append('bukti_tf', event.target.bukti_tf.files[0]);
        }

        if (detail.verif_bukti == 1) {
            formData.append('verif_bukti', 0)
        }

        if (detail.verif_bukti_tf == 1) {
            formData.append('verif_bukti_tf', 0)
        }

        formData.append('nominal', detail.nominal);
        formData.append('kode_lembaga', homebase);
        formData.append('is_checker', detail.is_checker);
        formData.append('is_approve', detail.is_approve);
        formData.append('selesai', detail.selesai);

        UpdateTransaksiUnit(homebase, id, formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil diubah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true) {
                    navigate('/trx-unit');
                }
            });
        }).catch(response => {
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal ditambah",
                type: "error",
            });
        });
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Transaksi Unit Detail</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                {detail.length !== 0 ?
                                    <>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Anggaran
                                            </div>
                                            <div className="col-md-5">
                                                {detail.anggaran && detail.anggaran.nama_kegiatan}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Keterangan
                                            </div>
                                            <div className="col-md-5">
                                                {detail.keterangan}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Total Anggaran
                                            </div>
                                            <div className="col-md-5">
                                                <b>{detail.anggaran && <FormatRupiah value={detail.anggaran.total_anggaran} />}</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Total Transaksi
                                            </div>
                                            <div className="col-md-5">
                                                <b><FormatRupiah value={detail.total_transaksi} /></b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Tanggal
                                            </div>
                                            <div className="col-md-5">
                                                {Moment(detail.tanggal).format('DD MMMM YYYY')}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Status
                                            </div>
                                            <div className="col-md-5">
                                                {detail.is_checker == true ?
                                                    <span className="badge badge-success text-success"> Checker </span> :
                                                    <span className="badge badge-danger text-danger"> Checker </span>} <br />
                                                {detail.is_approve == true ?
                                                    <span className="badge badge-success text-success"> Approval </span> :
                                                    <span className="badge badge-danger text-danger"> Approval </span>}
                                            </div>
                                        </div>

                                        {detail.note_bukti != '' ? <>
                                            <br />
                                            <div className="row">
                                                <div className="col-md-2">
                                                    Catatan
                                                </div>
                                                <div className="col-md-5">
                                                    {detail.note_bukti}
                                                </div>
                                            </div></> : null}

                                        <form onSubmit={handleSubmit}>
                                            {detail.verif_bukti < 2 ?
                                                <div className="row mt-3">
                                                    <div className="col-md-2">
                                                        Bukti Nota
                                                    </div>
                                                    {rekTujuan.toLowerCase().includes('pengadaan') ?
                                                        <div className="col-md-5">
                                                            <input className="form-control" type="file" name="bukti" />
                                                        </div> :
                                                        <div className="col-md-5">
                                                            <input className="form-control" type="file" name="bukti" required />
                                                        </div>
                                                    }
                                                </div> : null}

                                            {detail.verif_bukti_tf < 2 ?
                                                <div className="row mt-3">
                                                    <div className="col-md-2">
                                                        Bukti Transfer
                                                    </div>
                                                    <div className="col-md-5">
                                                        <input className="form-control" type="file" name="bukti_tf" required />
                                                    </div>
                                                </div> : null}
                                            <br />
                                            <Link to="/trx-unit" className="btn btn-sm btn-secondary me-2">
                                                <span className="material-icons md-18 me-1">chevron_left</span>Kembali
                                            </Link>
                                            <button className="btn btn-sm btn-success" type="submit">
                                                <span className="material-icons md-18 me-1">save</span>Selesai
                                            </button>
                                        </form>
                                    </> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrxUnitUploadBukti