import { GetKodeAnggaranDetail, UpdateKodeAnggaran } from "../../../services/KeuanganService";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

const KodeAnggaranDetail = () => {
    let [data, setData] = useState({});
    const { id } = useParams();

    useEffect(() => {
        GetKodeAnggaranDetail(id).then((response) => {
            setData(response.data);
        }).catch(() => console.log('error get_kode_anggaran_detail'));
    }, [id])

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Kode Anggaran Detail</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            Kode Anggaran <br />
                                            {data.kode}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            Keterangan <br />
                                            {data.keterangan}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <Link to="/mata-anggaran" className="btn btn-sm btn-secondary">
                                    <span className="material-icons md-18 me-1">chevron_left</span>Kembali
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KodeAnggaranDetail