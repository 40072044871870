
function CustomSearch({ setSearch }) {

    return (
        <div className="pagination justify-content-end mt-2 mb-4">
            <div className="ms-md-auto md-3 d-flex align-items-center">
                <div className="form-group">
                    <div className="input-group">
                        <input type="text" name="q"
                            onChange={x => setTimeout(() => { setSearch(x.target.value) }, 1200)}
                            className="form-control" placeholder="Search here..." />

                        {/* <span className="input-group-text">
                            <span className="material-icons text-primary">search</span>
                        </span> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomSearch