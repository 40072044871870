import Moment from 'moment';
import {Cookies} from 'react-cookie';
import React, {useEffect, useState} from "react";

import {Link, useNavigate} from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import Select from 'react-select';
import {FormatRupiah} from '@arismun/format-rupiah';
import {header, headerCenter, tede, rangez, numTable, swalCustom} from '../../../components/CustomStyle';

import {DeleteTransaksiUnit, GetPeriodeList, GetRequestTrxUnitEdit, GetTransaksiUnitListV2} from "../../../services/KeuanganService";
import CustomPagin from '../../../components/CustomPagin.js';
import CustomSearch from '../../../components/CustomSearch.js';

const TrxUnitList = () => {
    const url = '/trx-unit/detail/'
    const url_edit = '/trx-unit/edit/'
    const url_upload_bukti = '/trx-unit-bukti/'

    let navigate = useNavigate();
    let cookies = new Cookies();

    let homebase = cookies.get('homebase_kode');
    let homebaseNama = cookies.get('homebase_nama');
    let homebaseRekening = cookies.get('homebase_rekening');
    
    let [page, setPage] = useState(1);
    let [limit, setLimit] = useState(10);
    let [search, setSearch] = useState('');

    let [data, setData] = useState([]);
    let [dataPagin, setDataPagin] = useState({});
    let [periode, setPeriode] = useState(0);
    let [periodeList, setPeriodeList] = useState([]);

    const getData = async () => {
        await GetTransaksiUnitListV2(homebase, periode, homebaseRekening, page, limit, search).then((response) => {
            setData(response.data.rows);
            setDataPagin(response.data);

        }).catch(() => console.log('error GetTransaksiUnitListV2'));
    }

    const getPeriode = async () => {
        await GetPeriodeList().then((response) => {
            setPeriodeList(response.data.rows);
            setPeriode(response.data.rows[0]['id']);

        }).catch(() => console.log('error GetPeriodeList'));
    }

    useEffect(() => {
        getPeriode();
    }, [homebase, homebaseRekening])

    useEffect(() => {
        getData();
    }, [homebase, periode, homebaseRekening, page, search])

    const opensweetalert = (id) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin menghapus ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                DeleteTransaksiUnit(homebase, id).then(() => {
                    swalCustom.fire('Berhasil dihapus!', '', 'success')
                    getData();
                })
            }
        })
    }

    const ajukaneditswal = (id) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin mengajukan edit transaksi ini ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                GetRequestTrxUnitEdit(id).then(response => {
                    swalCustom.fire('Berhasil diajukan!', '', 'success')
                    getData();
                })
            }
        })
    }

    const handleRowClick = (row) => {
        let path = url + row.id; 
        navigate(path);
    };

    function change(event){
        setPeriode(event.target.value);
        setData([]);
    }

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Transaksi Unit</h5>
                                <span className="text-bold">{homebase} - {homebaseNama}</span>
                            </div>

                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto">
                                    <Link to="/trx-unit/form" className="btn btn-xs btn-success">
                                        <span className="material-icons md-18 me-1">add_box</span>
                                        Tambah
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        Periode
                        {periodeList.length !== 0 &&
                            <select className="form-select" onChange={change}>
                                {periodeList.map((periode) =>
                                    <option key={periode.id} value={periode.id}>{periode.tahun}</option>
                                )}
                            </select>
                        }
                        <br/>
                        {data !== undefined && 
                            <>
                            <CustomSearch setSearch={setSearch} />
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th style={header}>No</th>
                                            <th style={header}>Anggaran</th>
                                            <th style={headerCenter}>Total Transaksi</th>
                                            <th style={header}>Status</th>
                                            <th style={header}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {data.map((row, rowIndex) => {
                                        let namas_pajak = row.anggaran.nama_kegiatan.toLowerCase() !== 'pajak';
                                        let namas_admin = row.anggaran.nama_kegiatan.toLowerCase() !== 'biaya administrasi';

                                        return (
                                            <tr key={rowIndex} onClick={() => handleRowClick(row)} className="text-sm font-weight-normal">
                                                <td>{numTable(page, rowIndex)}</td>
                                                <td style={{whiteSpace: 'normal'}}>
                                                    <span className="text-xs">
                                                        <i>{Moment(row.tanggal).format('DD MMMM YYYY hh:mm')} {row.created_by}</i>
                                                    </span><br/>
                                                    <b>{row.anggaran.nama_kegiatan}</b><br/>
                                                    {row.keterangan}
                                                </td>
                                                <td style={{textAlign: 'center'}}>
                                                    <b><FormatRupiah value={row.total_transaksi} /></b><br/>
                                                    {row.status_trx == 1 
                                                        ? <span className="badge badge-sm badge-info">Kedalam</span>
                                                        : <span className="badge badge-sm badge-warning">Keluar</span>
                                                    }
                                                </td>
                                                <td>
                                                    {row.is_approve === false 
                                                        ? <span className="badge badge-secondary opacity-6">Approval</span> 
                                                        : <span className="badge badge-success">Approval</span>
                                                    }
                                                </td>
                                                <td>
                                                    {row.selesai == 0 &&
                                                        <>
                                                        <Link to={url_edit+row.id} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-warning me-1'>
                                                            <span className="material-icons md-18">edit</span>
                                                        </Link>
                                                        <button onClick={(e) => {e.stopPropagation(); opensweetalert(row.id)}} className='btn btn-xs btn-danger'>
                                                            <span className="material-icons md-18">delete</span>
                                                        </button>
                                                        </>
                                                    }

                                                    {(namas_pajak && namas_admin && row.selesai == 1 && row.is_checker == 1 && row.is_approve == 1 && row.status_trx == 0 && row.bukti == null 
                                                    || namas_pajak && namas_admin && row.selesai == 1 && row.is_checker == 1 && row.is_approve == 1 && row.status_trx == 0 && row.bukti_tf == null) 
                                                        &&
                                                        <Link to={url_upload_bukti+row.id} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-info'>
                                                            <span className="material-icons md-18 me-1">file_upload</span> Upload Bukti
                                                        </Link>
                                                    }
                                                    
                                                    {(row.is_approve == 1 && row.verif_bukti == 1 || row.is_approve == 1 && row.verif_bukti_tf == 1) &&
                                                        <Link to={url_upload_bukti+row.id} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-info'>
                                                            <span className="material-icons md-18 me-1">file_upload</span> Revisi Bukti
                                                        </Link>
                                                    }

                                                    {row.is_edit == 1 && 
                                                        <Link to={url_edit+row.id} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-warning me-1'>
                                                            <span className="material-icons md-18">edit</span>
                                                        </Link>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                            <CustomPagin data_pagin={dataPagin} setPage={setPage} />
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TrxUnitList