import { rangez } from "./CustomStyle"

function CustomPagin({data_pagin, setPage}){
    let prev = data_pagin['prev_page']
    let next = data_pagin['next_page']
    let page = data_pagin['current_page']
    let data_now = data_pagin['page_size']
    let data_total = data_pagin['records']
    let page_total = data_pagin['total_pages']

    let start_index = page > 1 ? (page - 1) * 10 + 1  : page
    let end_index = page != page_total ? page * data_now : data_total

    if (data_total == 0){
        start_index = 0
    }
    
    return (
        <>
        Showing {start_index} to {end_index} of {data_total} entries
        <ul className="pagination justify-content-end">
            {prev 
                ?
                <li className="page-item">
                    <button className="page-link" onClick={(e) => prev == true ? setPage(page-1) : null}>
                        <span className="material-icons md-24">chevron_left</span>
                    </button>
                </li>
                :
                <li className="page-item disabled">
                    <button className="page-link">
                        <span className="material-icons md-24 opacity-5">chevron_left</span>
                    </button>
                </li>
            }

            {rangez(1, page_total+1).map(datax => 
                {return datax == page 
                ? <li className="page-item active">
                        <button className="page-link">
                            {datax}
                        </button>
                    </li>
                : page < datax + 3 && page > datax - 3 
                ? <li className="page-item">
                        <button className="page-link" onClick={(e) => setPage(datax)}>
                            {datax}
                        </button>
                    </li>
                :null}
            )}

            {next 
                ? <li className="page-item">
                    <button className="page-link" onClick={(e) => next == true ? setPage(page+1) : null}>
                        <span className="material-icons md-24">chevron_right</span>
                    </button>
                    </li>
                : <li className="page-item disabled">
                    <button className="page-link">
                        <span className="material-icons md-24 opacity-5">chevron_right</span>
                    </button>
                    </li>
            }
        </ul>
        </>
    )
}

export default CustomPagin