import { Cookies } from 'react-cookie';
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";

import { swalCustom } from "../../../components/CustomStyle";
import { useNavigate, useParams, Link } from "react-router-dom";
import { GetAnggaranDetail, GetPeriodeList, UpdateAnggaran } from "../../../services/KeuanganService";

const AnggaranEdit = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    let cookies = new Cookies();
    let username = cookies.get('username');
    let homebase = cookies.get('homebase_kode');

    let [total, setTotal] = useState();
    let [detail, setDetail] = useState({});
    let [periode, setPeriode] = useState([]);

    const getPeriode = () => {
        GetPeriodeList().then((response) => {
            setPeriode(response.data.rows);

        }).catch(() => console.log('error get_kode_anggaran_all'));
    }

    const getDetail = () => {
        GetAnggaranDetail(homebase, id).then((response) => {
            setDetail(response.data);
            setTotal(response.data.total_anggaran);

        }).catch(() => console.log('error get_periode_all'));
    }

    useEffect(() => {
        getPeriode();
        getDetail();
    }, [id])

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('periode_id', event.target.periode.value);
        formData.append('jenis_anggaran', detail.jenis_anggaran);
        formData.append('nama_kegiatan', event.target.kegiatan.value);
        formData.append('total_anggaran', event.target.total_anggaran.value.replace(/[^0-9.]/g, ''));
        formData.append('kode_lembaga', detail.kode_lembaga);
        formData.append('created_by', username);

        let namas_pajak = event.target.kegiatan.value.toLowerCase() !== 'pajak';
        let namas_admin = event.target.kegiatan.value.toLowerCase() !== 'biaya administrasi';

        if (detail.jenis_anggaran == "Non Apple" && namas_pajak && namas_admin) {
            if (event.target.bukti.files[0] !== undefined) {
                formData.append('bukti_pengajuan', event.target.bukti.files[0]);
            }
        }

        UpdateAnggaran(homebase, id, formData).then(() => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil diubah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true) {
                    navigate('/anggaran');
                }
            });
        }).catch(err => swalCustom.fire(err.response.data.message, '', 'fail'));
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Anggaran Edit</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                Periode
                                                <select className="form-control" name="periode" value={detail.periode}
                                                    onChange={x => setDetail({ ...detail, periode: x.target.value })}>
                                                    {periode.map((v, k) => {
                                                        return <option value={v.id}>{v.tahun}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Jenis Anggaran
                                                {detail.jenis_anggaran == 'Apple' ?
                                                    <input className="form-control" value="Apple" readOnly /> :
                                                    <input className="form-control" value="Non Apple" readOnly />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Nama Kegiatan
                                                <input className="form-control" type="text" name="kegiatan" required
                                                    value={detail.nama_kegiatan}
                                                    onChange={x => setDetail({ ...detail, nama_kegiatan: x.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Total Anggaran
                                                {total != null ?
                                                    <CurrencyInput
                                                        name="total_anggaran"
                                                        className="form-control"
                                                        prefix="Rp "
                                                        defaultValue={detail.total_anggaran}
                                                        onChange={x => setDetail({ ...detail, total_anggaran: x.target.value })}
                                                        decimalsLimit={2}
                                                        required /> : null}
                                            </div>
                                        </div>
                                        {detail.jenis_anggaran == "Non Apple"
                                            && detail.nama_kegiatan.toLowerCase() !== 'pajak'
                                            && detail.nama_kegiatan.toLowerCase() !== 'biaya administrasi' ?
                                            <div className="col-12">
                                                <div className="form-group">
                                                    Bukti Pengajuan
                                                    <p>File saat ini: {detail.bukti_pengajuan}</p>
                                                    <input className="form-control" type="file" name="bukti" />
                                                </div>
                                            </div> : null}
                                    </div>
                                    <br />
                                    <Link to="/anggaran" className="btn btn-xs btn-secondary me-1">
                                        <span className="material-icons md-18 me-1">chevron_left</span>Kembali
                                    </Link>
                                    <button className="btn btn-xs btn-success" type="submit">
                                        <span className="material-icons md-18 me-1">save</span>Simpan
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnggaranEdit