import axios from "axios";
import TokenService from "../../../services/TokenService";

import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import { DeleteRab, GetDataExcelRab, GetPeriodeList, GetRabListPeriode } from "../../../services/KeuanganService";
import { header, headerStickLeft, numTable, swalCustom } from '../../../components/CustomStyle';
import { FormatRupiah } from '@arismun/format-rupiah';
import { api_export_rab } from '../../../services/ApiService';
import { RabTotal } from "../../../components/RabTotal";
import { RabLabel } from "../../../components/RabLabel";
import { RabParent } from "../../../components/RabParent";
import { RabChild } from "../../../components/RabChild";

const RabList = () => {
    const url = '/rab/detail/'
    const url_edit = '/rab/edit/'
    const { SearchBar } = Search;
    let [data, setData] = useState([]);

    let [dataMasuk, setDataMasuk] = useState([]);
    let [totalMasuk, setTotalMasuk] = useState({});

    let [dataKeluarRutin, setDataKeluarRutin] = useState([]);
    let [totalKeluarRutin, setTotalKeluarRutin] = useState({});

    let [dataKeluarNoRutin, setDataKeluarNoRutin] = useState([]);
    let [totalKeluarNoRutin, setTotalKeluarNoRutin] = useState({});

    const navigate = useNavigate();
    let [page, setPage] = useState(1);
    let [periode, setPeriode] = useState('');
    let [periodeList, setPeriodeList] = useState([]);

    const getDataExcelRabPeriode = () => {
        GetDataExcelRab(periode).then((response) => {
            setDataMasuk(response.data.rows.list_penerimaan);
            setTotalMasuk(response.data.rows.total_penerimaan);

            setDataKeluarRutin(response.data.rows.list_rutin);
            setTotalKeluarRutin(response.data.rows.total_rutin);

            setDataKeluarNoRutin(response.data.rows.list_no_rutin);
            setTotalKeluarNoRutin(response.data.rows.total_no_rutin);

        }).catch(() => console.log('error get RAB Excel'));
    }

    const getRabPeriode = () => {
        GetRabListPeriode(periode).then((response) => {
            setData(response.data.rows);

        }).catch(() => console.log('error get RAB'));
    }

    const getPeriode = () => {
        GetPeriodeList().then((response) => {
            setPeriodeList(response.data.rows);
            setPeriode(response.data.rows[0]['tahun']);

        }).catch(() => console.log('error get Periode RAB'));
    }

    useEffect(() => {
        getPeriode();
    }, [])

    useEffect(() => {
        getRabPeriode();
        getDataExcelRabPeriode();
    }, [periode])

    const delsweetalert = (kode) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin menghapus ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                DeleteRab(kode).then(response => {
                    swalCustom.fire('Berhasil dihapus!', '', 'success')
                    // getData();
                    getRabPeriode();
                }).catch(err => swalCustom.fire(err.response.data.message, '', 'error'));
            }
        });
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Nama',
        dataField: 'nama',
        headerStyle: header
    }, {
        text: 'Nominal',
        dataField: 'nominal',
        formatter: (cell, row, rowIndex) => {
            return <b><FormatRupiah value={row.nominal} /></b>;
        },
        headerStyle: header
    }, {
        text: 'Realisasi',
        dataField: 'created_at',
        formatter: (cell, row, rowIndex) => {
            return <>{row.persentase_penggunaan} %</>
        },
        headerStyle: header
    }, {
        text: 'Tahun',
        dataField: 'tahun',
        headerStyle: header
    }, {
        text: 'PIC',
        dataField: 'pic',
        headerStyle: header
    }, {
        text: 'Action',
        dataField: 'button',
        formatter: (cell, row, rowIndex) => {
            return <>
                <Link to={url_edit + row.id} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-warning me-1'>
                    <span className="material-icons md-18">edit</span>
                </Link>
                <button onClick={(e) => { e.stopPropagation(); delsweetalert(row.id) }} className='btn btn-xs btn-danger'>
                    <span className="material-icons md-18">delete</span>
                </button>
            </>
        },
        headerStyle: header
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            let path = url + row.id;
            navigate(path);
        }
    }

    function changePeriode(event) {
        setPeriode(event.target.value);
        setData([]);
    }

    const getExcel = () => {
        let url_xlsx = api_export_rab + periode;

        axios({
            url: url_xlsx,
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Authorization': TokenService.getBearerAccessToken()
            }

        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', 'rab-' + periode.replace(/ /g, '') + '.xlsx');
            document.body.appendChild(link);
            link.click();
        });
    }

    let [tdClick, setTdClick] = useState(0);
    useEffect(() => {
        if (tdClick == 1) {
            navigate('/mata-anggaran');
        } else if (tdClick == 2) {
            navigate('/rab');
        }

    }, [tdClick]);

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="table-responsive m-1">
                        <table className="table" id="nav-table">
                            <tbody>
                                <tr>
                                    <td id="nav-no-active" onClick={() => { setTdClick(1) }}>
                                        <b className="text-xs">Mata Agr</b>
                                    </td>
                                    <td id="nav-active" onClick={() => { setTdClick(2) }}>
                                        <b className="text-xs">RAB</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">RAB</h5>
                            </div>

                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto">
                                    <button className="btn btn-xs btn-outline-success" onClick={getExcel}>
                                        <span className="material-icons text-sm">file_download</span>Export
                                    </button>
                                    <Link to="/rab/form" className="btn btn-xs btn-success ms-2">
                                        <span className="material-icons md-18 me-1">add_box</span> Tambah
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        Periode
                        {periodeList.length !== 0 ?
                            <select className="form-select" onChange={changePeriode}>
                                {periodeList.map((periode) =>
                                    <option key={periode.id} value={periode.tahun}>{periode.tahun}</option>
                                )}
                            </select> : null}
                        <br />
                        <ToolkitProvider
                            keyField="kode"
                            data={data}
                            columns={columns}
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar {...props.searchProps} />
                                        <br />
                                        <BootstrapTable
                                            keyField="id"
                                            {...props.baseProps}
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                                }
                                            })}
                                            bordered={false}
                                            rowEvents={rowEvents}
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            rowClasses="text-sm font-weight-normal"
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
            </div>

            <div className="col-12 mt-4">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Daftar RAB</h5>
                            </div>
                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table id="tabel_rab" className="table">
                                <thead>
                                    <tr>
                                        <th style={headerStickLeft}>Nama</th>
                                        <th style={header}>Pagu</th>
                                        <th style={header}>September</th>
                                        <th style={header}>Oktober</th>
                                        <th style={header}>November</th>
                                        <th style={header}>Desember</th>
                                        <th style={header}>Januari</th>
                                        <th style={header}>Februari</th>
                                        <th style={header}>Maret</th>
                                        <th style={header}>April</th>
                                        <th style={header}>Mei</th>
                                        <th style={header}>Juni</th>
                                        <th style={header}>Juli</th>
                                        <th style={header}>Agustus</th>
                                        <th style={header}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <RabLabel title={'Penerimaan'} />
                                    {dataMasuk.map((row, rowIndex) =>
                                        <RabParent key={rowIndex} row={row}>
                                            {row.child.map((rowChild, rowChildIndex) =>
                                                <RabChild key={rowChildIndex} row={rowChild} />
                                            )}
                                        </RabParent>
                                    )}
                                    <RabTotal row={totalMasuk} />

                                    <RabLabel title={'Pengeluaran Rutin'} />
                                    {dataKeluarRutin.map((row, rowIndex) =>
                                        <RabParent key={rowIndex} row={row}>
                                            {row.child.map((rowChild, rowChildIndex) =>
                                                <RabChild key={rowChildIndex} row={rowChild} />
                                            )}
                                        </RabParent>
                                    )}
                                    <RabTotal row={totalKeluarRutin} />

                                    <RabLabel title={'Pengeluaran No Rutin'} />
                                    {dataKeluarNoRutin.map((row, rowIndex) =>
                                        <RabParent key={rowIndex} row={row}>
                                            {row.child.map((rowChild, rowChildIndex) =>
                                                <RabChild key={rowChildIndex} row={rowChild} />
                                            )}
                                        </RabParent>
                                    )}
                                    <RabTotal row={totalKeluarNoRutin} />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RabList