import { GetKodeAnggaranDetail, UpdateKodeAnggaran } from "../../../services/KeuanganService";
import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { swalCustom } from "../../../components/CustomStyle";

const KodeAnggaranEdit = () => {
    let [data, setData] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        GetKodeAnggaranDetail(id).then((response) => {
            setData(response.data);
        }).catch(() => console.log('error get_kode_anggaran_detail'));
    }, [id])

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('kode', event.target.kode.value);
        formData.append('keterangan', event.target.keterangan.value);

        UpdateKodeAnggaran(id, formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil diubah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true) {
                    navigate('/mata-anggaran');
                }
            });
        }).catch(response => {
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal diubah",
                type: "error",
            });
        });
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Kode Anggaran Edit</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                Kode Anggaran
                                                <input type="text" name="kode" value={data.kode} className="form-control"
                                                    onChange={x => setData({ ...data, kode: x.target.value })} required />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Keterangan
                                                <input className="form-control" type="text" value={data.keterangan} name="keterangan"
                                                    onChange={x => setData({ ...data, keterangan: x.target.value })} required />
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <Link to="/mata-anggaran" className="btn btn-xs btn-secondary me-1">
                                        <span className="material-icons md-18 me-1">chevron_left</span>Kembali
                                    </Link>
                                    <button className="btn btn-xs btn-success" type="submit">
                                        <span className="material-icons md-18 me-1">save</span>Simpan
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KodeAnggaranEdit