import {
    api_anggaran, api_trx_unit_approve, api_trx_unit_checker, api_detail_trx_unit, api_kode_anggaran, api_mata_anggaran, api_anggaran_v2, 
    api_mata_anggaran_checker, api_mata_anggaran_approve, api_periode, api_sinkron_apple, api_trx_unit_list, api_list_user_group,
    api_add_user_group, api_delete_user_group, api_rekening, api_rekening_delegasi, api_get_user_group, api_list_rekening, api_sinkron_rekening,
    api_trx_unit_list_selesai, api_trx_unit_list_checked, api_mata_anggaran_list_selesai, api_mata_anggaran_list_checked, api_list_lembaga_anggaran,
    api_anggaran_by_lembaga, api_log_rekening, api_unit_kerja, api_homebase, api_trx_unit_tolak_checker, api_trx_unit_tolak_approve,
    api_mata_anggaran_tolak_checker, api_mata_anggaran_tolak_approve, api_trx_unit_verifikasi, api_trx_unit_verifikasi_detail, api_anggaran_periode, 
    api_anggaran_by_lembaga_v2, api_trx_unit_list_periode, api_rab, api_pegawai, token_temp, api_log_rekening_v2, api_mata_anggaran_v2, api_rab_v2, 
    api_rekening_v2, api_rekening_delegasi_v2, api_list_rekening_v2, api_trx_agr_edit, api_trx_agr_acc, api_trx_unit_edit, api_trx_unit_acc, 
    api_trx_unit_edit_list, api_trx_agr_edit_list, api_list_rekening_lembaga, api_anggaran_detail, api_sinkron_kegiatan_apple, api_data_export_rab,
    api_trx_unit_nota_list,
    api_peralihan_anggaran,
    api_peralihan_anggaran_admin, api_mata_anggaran_detail, api_list_rekening_all
} from "./ApiService";
import client from "./ApiClientKeuangan";

export function GetKodeAnggaranList(){
    return client.get(api_kode_anggaran)
}

export function GetKodeAnggaranDetail(id){
    return client.get(api_kode_anggaran+'/'+id)
}

export function PostKodeAnggaran(data){
    return client.post(api_kode_anggaran, data)
}

export function UpdateKodeAnggaran(id, data){
    return client.put(api_kode_anggaran+'/'+id, data)
}

export function DeleteKodeAnggaran(id){
    return client.delete(api_kode_anggaran+'/'+id)
}

export function GetPeriodeList(){
    return client.get(api_periode)
}

export function GetPeriodeDetail(tahun){
    return client.get(api_periode+'/'+tahun)
}

export function PostPeriode(data){
    return client.post(api_periode, data)
}

export function UpdatePeriode(tahun, data){
    return client.put(api_periode+'/'+tahun, data)
}

export function DeletePeriode(tahun){
    return client.delete(api_periode+'/'+tahun)
}

export function GetMataAnggaranList(tipe){
    return client.get(api_mata_anggaran+'/'+tipe)
}

export function GetMataAnggaranListV2(mag, tipe){
    return client.get(api_mata_anggaran_v2+'/'+mag+'/'+tipe)
}

export function GetMataAnggaranDetail(id){
    return client.get(api_mata_anggaran_detail+'/'+id)
}

export function PostMataAnggaran(tipe, data){
    return client.post(api_mata_anggaran+'/'+tipe, data)
}

export function UpdateMataAnggaran(id, data){
    return client.put(api_mata_anggaran_detail+'/'+id, data)
}

export function DeleteMataAnggaran(id){
    return client.delete(api_mata_anggaran_detail+'/'+id)
}

export function UpdateCheckerMataAnggaran(id){
    return client.put(api_mata_anggaran_checker+id, null)
}

export function TolakCheckerMataAnggaran(id, data){
    return client.put(api_mata_anggaran_tolak_checker+id, data)
}

export function UpdateApproveMataAnggaran(id){
    return client.put(api_mata_anggaran_approve+id, null)
}

export function TolakApproveMataAnggaran(id, data){
    return client.put(api_mata_anggaran_tolak_approve+id, data)
}

export function GetAnggaranList(homebase){
    return client.get(api_anggaran+homebase)
}

export function GetAnggaranListV2(homebase, periode){
    return client.get(api_anggaran_v2+homebase+'/'+periode)
}

export function GetAnggaranPeriodeNow(homebase){
    return client.get(api_anggaran_periode+homebase)
}

export function GetAnggaranDetail(homebase, id){
    return client.get(api_anggaran+homebase+'/'+id)
}

export function GetAnggaranDetailV2(homebase, periode, id){
    return client.get(api_anggaran_v2+homebase+'/'+periode+'/'+id)
}

export function PostAnggaran(homebase, data){
    return client.post(api_anggaran+homebase, data)
}

export function UpdateAnggaran(homebase, id, data){
    return client.put(api_anggaran+homebase+'/'+id, data)
}

export function DeleteAnggaran(homebase, id){
    return client.delete(api_anggaran+homebase+'/'+id)
}

export function GetTransaksiUnitList(homebase){
    return client.get(api_trx_unit_list+homebase+'/list/')
}

export function GetTransaksiUnitListV2(homebase, periode, rek, page, limit, search){
    return client.get(api_trx_unit_list_periode+homebase+'/list/'+periode+'/rek/'+rek, {
        params: {
            page: page, 
            limit: limit, 
            search: search
        }
    })
}

export function GetTransaksiUnitDetail(homebase, id){
    return client.get(api_trx_unit_list+homebase+'/detail/'+id)
}

export function PostTransaksiUnit(homebase, data){
    return client.post(api_trx_unit_list+homebase+'/list/', data)
}

export function UpdateTransaksiUnit(homebase, id, data){
    return client.put(api_trx_unit_list+homebase+'/detail/'+id, data)
}

export function DeleteTransaksiUnit(homebase, id){
    return client.delete(api_trx_unit_list+homebase+'/detail/'+id)
}

export function PostDetailTransaksiUnit(homebase, id, data){
    return client.post(api_detail_trx_unit+homebase+'/list/'+id, data)
}

export function UpdateDetailTransaksiUnit(homebase, id, data){
    return client.put(api_detail_trx_unit+homebase+'/detail/'+id, data)
}

export function DeleteDetailTransaksiUnit(homebase, id){
    return client.delete(api_detail_trx_unit+homebase+'/detail/'+id)
}

export function UpdateCheckerTransaksiUnit(id){
    return client.put(api_trx_unit_checker+id, null)
}

export function TolakCheckerTransaksiUnit(id, data){
    return client.put(api_trx_unit_tolak_checker+id, data)
}

export function UpdateApproveTransaksiUnit(id){
    return client.put(api_trx_unit_approve+id, null)
}

export function TolakApproveTransaksiUnit(id, data){
    return client.put(api_trx_unit_tolak_approve+id, data)
}

export function GetSinkronApple(homebase, periode){
    return client.get(api_sinkron_apple+homebase+'/'+periode);
}

export function GetSinkronKegiatanApple(homebase, periode){
    return client.get(api_sinkron_kegiatan_apple+homebase+'/'+periode);
}

export function GetUserGroupList(){
    return client.get(api_list_user_group);
}

export function PostUserGroup(username, group){
    return client.put(api_add_user_group+username+'/'+group, null);
}

export function DeleteUserGroup(username, group){
    return client.put(api_delete_user_group+username+'/'+group, null);
}

export function GetRekeningList(homebase){
    return client.get(api_rekening+homebase)
}

export function GetRekeningListV2(homebase, rekening){
    return client.get(api_rekening_v2+homebase+'/'+rekening)
}

export function UpdateRekeningList(homebase, data){
    return client.put(api_rekening+homebase, data)
}

export function UpdateRekeningListV2(homebase, rekening, data){
    return client.put(api_rekening_v2+homebase+'/'+rekening, data)
}

export function GetRekeningDelegasiList(homebase){
    return client.get(api_rekening_delegasi+homebase)
}

export function GetRekeningDelegasiListV2(homebase, rekening){
    return client.get(api_rekening_delegasi_v2+homebase+'/'+rekening)
}

export function PostRekeningDelegasiList(homebase, data){
    return client.post(api_rekening_delegasi+homebase, data)
}

export function PostRekeningDelegasiListV2(homebase, rekening, data){
    return client.post(api_rekening_delegasi_v2+homebase+'/'+rekening, data)
}

export function DeleteRekeningDelegasiList(homebase, delegator){
    return client.delete(api_rekening_delegasi+homebase+'/delete/'+delegator)
}

export function DeleteRekeningDelegasiListV2(homebase, delegator, rekening){
    return client.delete(api_rekening_delegasi_v2+homebase+'/delete/'+delegator+'/'+rekening)
}

export function GetUserGroupByToken(){
    return client.get(api_get_user_group)
}

export function GetAllRekening(){
    return client.get(api_list_rekening_all)
}

export function PostRekeningAdmin(data){
    return client.post(api_list_rekening, data)
}

export function UpdateRekeningAdmin(kode_lembaga, nomor_rekening, id, data){
    return client.put(api_list_rekening_v2+'/'+kode_lembaga+'/'+nomor_rekening+'/'+id, data)
}

export function GetSinkronAllRekening(){
    return client.get(api_sinkron_rekening)
}

export function GetTrxUnitListSelesai(){
    return client.get(api_trx_unit_list_selesai)
}

export function GetTrxUnitListChecked(){
    return client.get(api_trx_unit_list_checked)
}

export function GetMataAnggaranListSelesai(){
    return client.get(api_mata_anggaran_list_selesai)
}

export function GetMataAnggaranListChecked(){
    return client.get(api_mata_anggaran_list_checked)
}

export function GetListLembagaAnggaran(periode){
    return client.get(api_list_lembaga_anggaran+'/'+periode)
}

export function GetAnggaranByLembaga(kode_lembaga){
    return client.get(api_anggaran_by_lembaga+kode_lembaga)
}

export function GetAnggaranByLembagaV2(kode_lembaga, periode){
    return client.get(api_anggaran_by_lembaga_v2+kode_lembaga+'/'+periode)
}

export function GetDetailAnggaranByLembaga(kode_lembaga, id){
    return client.get(api_anggaran_by_lembaga+kode_lembaga+'/'+id)
}

export function GetPeralihanAnggaran(id){
    return client.get(api_peralihan_anggaran+'/'+id)
}

export function PostPeralihanAnggaran(id, data){
    return client.post(api_peralihan_anggaran+'/'+id, data)
}

export function GetPeralihanAnggaranListAdmin(){
    return client.get(api_peralihan_anggaran_admin)
}

export function GetPeralihanAnggaranDetailAdmin(id){
    return client.get(api_peralihan_anggaran_admin+'/'+id)
}

export function PutPeralihanAnggaranDetailAdmin(id, data){
    return client.put(api_peralihan_anggaran_admin+'/'+id, data)
}

export function GetLogRekeningUnit(homebase, start, end){
    return client.get(api_log_rekening+homebase+'/'+start+'/'+end)
}

export function GetLogRekeningUnitV2(homebase, start, end, anggaran){
    return client.get(api_log_rekening_v2+homebase+'/'+start+'/'+end+'/'+anggaran)
}

export function GetUnitKerja(){
    return client.get(api_unit_kerja)
}

export function GetHomebase(){
    return client.get(api_homebase)
}

export function GetTrxUnitVerifikasiList(kode_lembaga){
    return client.get(api_trx_unit_verifikasi + kode_lembaga)
}

export function GetDetailTrxUnitVerifikasi(id){
    return client.get(api_trx_unit_verifikasi_detail+id)
}

export function UpdateTrxUnitVerifikasiList(id, data){
    return client.put(api_trx_unit_verifikasi_detail+id, data)
}

export function GetRabList(){
    return client.get(api_rab)
}

export function GetRabListPeriode(periode){
    return client.get(api_rab_v2+'/'+periode)
}

export function GetRabDetail(id){
    return client.get(api_rab+'/'+id)
}

export function PostRab(data){
    return client.post(api_rab, data)
}

export function UpdateRab(id, data){
    return client.put(api_rab+'/'+id, data)
}

export function DeleteRab(id){
    return client.delete(api_rab+'/'+id)
}

export function GetPic(){
    return client.get(api_pegawai+'/', {headers: { 'TOKEN': token_temp } })
}

export function GetRequestTrxUnitEdit(id){
    return client.get(api_trx_unit_edit+id)
}

export function GetAcceptTrxUnitEdit(id){
    return client.get(api_trx_unit_acc+id)
}

export function GetRequestTrxAgrEdit(id){
    return client.get(api_trx_agr_edit+id)
}

export function GetAcceptTrxAgrEdit(id){
    return client.get(api_trx_agr_acc+id)
}

export function GetListTrxUnitEdit(){
    return client.get(api_trx_unit_edit_list)
}

export function GetListTrxAgrEdit(){
    return client.get(api_trx_agr_edit_list)
}

export function GetListRekeningLembaga(){
    return client.get(api_list_rekening_lembaga)
}

export function GetDetailAnggaran(kode_lembaga, periode){
    return client.get(api_anggaran_detail+kode_lembaga+'/'+periode)
}

export function GetDataExcelRab(periode){
    return client.get(api_data_export_rab+periode)
}

export function GetTransaksiUnitNotaList(homebase, periode){
    return client.get(api_trx_unit_nota_list+homebase+'/list/'+periode)
}
