import {useState} from "react";
import {Cookies} from "react-cookie";
import {useNavigate, useParams} from "react-router-dom";

import CurrencyInput from "react-currency-input-field";
import {swalCustom} from "../../../components/CustomStyle";
import {PostDetailTransaksiUnit} from "../../../services/KeuanganService";

const FormRincian = () => {
    const navigate = useNavigate();
    const {id} = useParams();

    let cookies = new Cookies();
    let homebase = cookies.get('homebase_kode');

    const [formFields, setFormFields] = useState([{
        transaksi_unit_id: id,
        rekening_tujuan: '', 
        keterangan: '', 
        nominal: ''
    }])

    const handleFormChange = (event, index) => {
        let data = [...formFields];
        data[index][event.target.name] = event.target.name == 'nominal' ? event.target.value.replace(/[^0-9.]/g, '') : event.target.value;
        setFormFields(data);
    }
    
    const addFields = () => {
        let object = {
            transaksi_unit_id: id,
            rekening_tujuan: '',
            keterangan: '',
            nominal: ''
        }
        setFormFields([...formFields, object])
    }
    
    const removeFields = (index) => {
        let data = [...formFields];
        data.splice(index, 1)
        setFormFields(data)
    }

    const submit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('rincian', JSON.stringify(formFields));

        PostDetailTransaksiUnit(homebase, id, formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil ditambah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true){
                    navigate('/trx-unit/detail/'+id);
                }
            });
        }).catch(response => {
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal ditambah",
                type: "error",
            });
        });
    };

    return (
        <>
        <div className="card">
            <div className="card-header inline-group">
                <div className="d-lg-flex">
                    <div>
                        <h5 className="mb-0">Rincian Transaksi Unit Form</h5>
                    </div>
                </div>
            </div>
            <div className="card shadow-none">
                <div className="card-body">
                    <div className="ms-auto my-auto mt-lg-0 mt-4">
                        <div className="ms-auto my-auto">
                            <button onClick={addFields} className="btn btn-xs btn-success">
                                <i className="fas fa-plus-square fa-lg me-2"></i>Tambah
                            </button>
                        </div>
                    </div>
                    <form onSubmit={submit}>
                        <table className='table-responsive'>
                            {formFields.map((form, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <input
                                                type='number'
                                                name='rekening_tujuan'
                                                placeholder='Rekening Tujuan'
                                                className='form-control'
                                                onChange={event => handleFormChange(event, index)}
                                                value={form.rekening_tujuan} />
                                        </td>
                                        <td>
                                            <input
                                                name='keterangan'
                                                placeholder='Keterangan'
                                                className='form-control'
                                                onChange={event => handleFormChange(event, index)}
                                                value={form.keterangan} />
                                        </td>
                                        <td>
                                            <CurrencyInput 
                                                name="nominal" 
                                                className="form-control" 
                                                prefix="Rp " 
                                                value={form.nominal}
                                                onChange={event => handleFormChange(event, index)}
                                                decimalsLimit={2} />

                                        </td>
                                        <td>
                                            <button 
                                                type="button"
                                                style={{margin: 'auto'}}
                                                className='btn btn-xs btn-danger'
                                                onClick={() => removeFields(index)}>
                                                    <i className="fa fa-trash me-2"></i>Hapus
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </table>
                        <br />
                        <button onClick={submit} className='btn btn-xs btn-success'>
                            <span className="material-icons md-18 me-1">save</span>Simpan
                        </button>
                    </form>
                </div>
            </div>
            <br />
        </div>
        </>
    )
}

export default FormRincian