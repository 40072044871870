import {Cookies} from 'react-cookie';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {FormatRupiah} from '@arismun/format-rupiah';
import CurrencyInput from "react-currency-input-field";
import {swalCustom} from '../../../components/CustomStyle';
import {GetAllRekening, GetAnggaranListV2, GetAnggaranPeriodeNow, GetRekeningList, PostTransaksiUnit} from "../../../services/KeuanganService";

const TrxUnitForm = () => {
    const navigate = useNavigate();

    let cookies = new Cookies();
    let username = cookies.get('username');
    let homebase = cookies.get('homebase_kode');
    let homebase_rekening = cookies.get('homebase_rekening');

    let [kode, setKode] = useState([]);
    let [isJenis, setIsJenis] = useState(0);
    let [isPayroll, setIsPayroll] = useState(false);
    let [isSelesai, setIsSelesai] = useState(false);
    let [isDisabled, setIsDisabled] = useState(false);

    let [isKet, setIsKet] = useState('');
    let [isBonus, setIsBonus] = useState(0);
    let [isPajakAdmin, setIsPajakAdmin] = useState(0);

    let [noRek, setNoRek] = useState();
    let [periodeNow, setPeriodeNow] = useState(0);
    let [periodeNowYear, setPeriodeNowYear] = useState('');

    let [rek, setRek] = useState([]);
    let [rekTujuan, setRekTujuan] = useState('');
    let [predictTujuan, setPredictTujuan] = useState([]);

    let [anggaranSisa, setAnggaranSisa] = useState(0);
    let [slcAnggaranName, setSlcAnggaranName] = useState('');
    let [nominalInput, setNominalInput] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);

    const INPUT_TIMEOUT = 250;

    const getPredictions = (value) => {        
        const cari_kode = rek.filter(item => String(item.kode_lembaga).toLowerCase().indexOf(value.toLowerCase()) !== -1);
        const cari_nama = rek.filter(item => String(item.nama).toLowerCase().indexOf(value.toLowerCase()) !== -1);
        const cari_rek = rek.filter(item => String(item.nomor_rekening).toLowerCase().indexOf(value.toLowerCase()) !== -1);
        let hasil = []

        if (cari_kode.length > 0){
            cari_kode.map(x => {
                if (hasil.includes(x) == false){
                    hasil.push(x)
                }
            });
        }

        if (cari_nama.length > 0){
            cari_nama.map(x => {
                if (hasil.includes(x) == false){
                    hasil.push(x)
                }
            });
        }

        if (cari_rek.length > 0){
            cari_rek.map(x => {
                if (hasil.includes(x) == false){
                    hasil.push(x)
                }
            });
        }

        return hasil;
    }

    const handleRekTujuan = (event) => {
        const value = event.target.value;
        setRekTujuan(value);
  
        if (value.length > 0) {
            setTimeout(() => {
                const predictions = getPredictions(value);
                setPredictTujuan(predictions);
            }, INPUT_TIMEOUT);
        } else {
            setPredictTujuan([]);
        }
    }

    const handleOptTujuan = (event) => {
        setRekTujuan(event.target.value);
        setPredictTujuan([]);
    }

    const getRekening = () => {
        GetAllRekening().then(response => {
            setRek(response.data.rows);
        })
    }

    const getRekHomebase = async () => {
        await GetRekeningList(homebase).then((response) => {
            setNoRek(response.data.nomor_rekening);

        }).catch(() => console.log('error GetRekeningList'));
    }

    const getKode = async () => {
        await GetAnggaranListV2(homebase, periodeNow).then((response) => {
            const excludedAgrMasuk = ['Termin 1', 'Termin 2', 'Termin 3', 'Bagi Hasil atau Bonus'];
            const excludedAgrKeluar = ['Pajak', 'Biaya Administrasi'];

            let agr_list = response.data.rows;

            if (isJenis == 0){
                agr_list = response.data.rows.filter((vals) => !excludedAgrMasuk.includes(vals.nama_kegiatan));
            }else{
                agr_list = response.data.rows.filter((vals) => !excludedAgrKeluar.includes(vals.nama_kegiatan));
            }

            if (agr_list.length > 0){
                setAnggaranSisa(agr_list[0].sisa_dana);
                setSlcAnggaranName(agr_list[0].nama_kegiatan);
            }
            setKode(agr_list);

        }).catch(() => console.log('error GetAnggaranListV2'));
    }

    const getPeriodeNow = async () => {
        await GetAnggaranPeriodeNow(homebase).then((response) => {
            setPeriodeNow(response.data.rows['id']);
            setPeriodeNowYear(response.data.rows['tahun']);

        }).catch(() => console.log('error GetAnggaranPeriodeNow'));
    }

    useEffect(() => {
        setShowTooltip(false);
        setNominalInput(0);
        getKode();
    }, [homebase, periodeNow, isJenis])

    useEffect(() => {
        getPeriodeNow();
        getRekening();
        getRekHomebase();
    }, [])

    const handleSubmit = async (event) => {
        setIsDisabled(true);
        event.preventDefault();

        let formData = new FormData();
        formData.append('anggaran_id', event.target.anggaran.value);
        formData.append('keterangan', event.target.keterangan.value);
        formData.append('status_trx', isJenis);
        
        let date_str = event.target.tanggal.value;
        let date_millis = new Date(date_str).getTime();
        formData.append('tanggal', date_millis);

        if (isJenis == 1 && isBonus == 0){
            formData.append('bukti_tf', event.target.bukti_tf.files[0]);
        }

        formData.append('kode_lembaga', homebase);
        formData.append('created_by', username);
        formData.append('is_checker', true);
        formData.append('is_approve', false);

        formData.append('is_payroll', isPayroll);
        formData.append('selesai', isSelesai);
        formData.append('rekening_aktif', homebase_rekening);
        formData.append('rekening_asal', homebase_rekening);


        if (isPayroll === false){
            if (isJenis == 0 && isPajakAdmin == 0){
                let rek_pajak = 1111;
                let rek_biaya_admin = 2222;
                let rekz = 0;
                
                try{
                    rekz = event.target.rekening_tujuan.value;
                }catch{
                    rekz = 0;
                }

                if (slcAnggaranName == 'Pajak'){
                    rekz = rek_pajak;
                }else if (slcAnggaranName == 'Biaya Administrasi'){
                    rekz = rek_biaya_admin;
                }
                formData.append('rekening_tujuan', rekz);
                // formData.append('rekening_tujuan', event.target.rekening_tujuan.value);
            }else{
                formData.append('rekening_tujuan', homebase_rekening);
            }
            formData.append('nominal', event.target.nominal.value.replace(/[^0-9.]/g, ''));

        }else{
            formData.append('nominal', 0);
        }

        await PostTransaksiUnit(homebase, formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil ditambah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true){
                    if (isPayroll === true){
                        navigate('/trx-unit/rincian-form/'+response.data.id)
                    }else{
                        navigate('/trx-unit');
                    }
                }
            });
        }).catch(response => {
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal ditambah",
                type: "error",
            });
        });
    };

    const handlePayroll = () => {
        setIsPayroll(!isPayroll);
    }

    const handleChangeNominalInput = (event) => {
        const input_val = event.target.value.replace(/[^0-9.]/g, '');
        console.log('ini adalah is jenis: ', isJenis);

        const excludeAgrName = ['Termin 1', 'Termin 2', 'Termin 3', 'Pajak', 'Biaya Administrasi', 'Bagi Hasil atau Bonus']

        if (!excludeAgrName.includes(slcAnggaranName)){

            if (isJenis == 0){
                if (input_val > anggaranSisa){
                    setShowTooltip(true);
                    setNominalInput(anggaranSisa);
                }else{
                    setShowTooltip(false);
                    setNominalInput(input_val);
                }
            }else{
                setShowTooltip(false);
                setNominalInput(input_val);
            }

        }else{
            console.log('220')
            setShowTooltip(false);
            setNominalInput(input_val);
        }
    }

    const handleChangeAnggaran = (event) => {
        const opt_label = event.target.selectedOptions[0].text;
        const opt_val = event.target.selectedOptions[0].value;

        if (opt_label == 'Pajak' || opt_label == 'Biaya Administrasi'){
            setIsPajakAdmin(1);
            setIsBonus(0);
            // setIsJenis(0);
        }else if (opt_label == 'Bagi Hasil atau Bonus'){
            setIsPajakAdmin(0);
            setIsBonus(1);
            // setIsJenis(1);
        }else{
            setIsPajakAdmin(0);
            setIsBonus(0);
            // setIsJenis(0);
        }

        const ket = opt_label.split('(')[0];
        setIsKet(ket);

        const selected_agr = kode.find(kod => kod.id == opt_val);
        setAnggaranSisa(selected_agr.sisa_dana);
        setSlcAnggaranName(selected_agr.nama_kegiatan);

        setNominalInput(0);
        setShowTooltip(false);
    }

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Transaksi Unit Form</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        {isPajakAdmin == 0 && isBonus == 0 ?
                                        <div className="col-12">
                                            <div className="form-group">
                                                Jenis Transaksi
                                                <select className="form-control" name="status_trx" 
                                                    onChange={(e) => {
                                                        setIsJenis(e.target.value);
                                                        if (e.target.value==1){
                                                            setIsPayroll(false);
                                                        }
                                                    }}>
                                                    <option value="0" selected>Keluar</option>
                                                    <option value="1">Masuk</option>
                                                </select>
                                            </div>
                                        </div>:null}
                                        <div className="col-12">
                                            <div className="form-group">
                                                Anggaran {periodeNowYear}
                                                <select className="form-control" name="anggaran" onChange={handleChangeAnggaran}>
                                                    {kode.map((v, k) => {
                                                        return <option value={v.id}>{v.nama_kegiatan} (<FormatRupiah value={v.sisa_dana} />)</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Keterangan
                                                <input className="form-control" type="text" name="keterangan" value={isKet} onChange={x => setIsKet(x.target.value)} required/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Tanggal
                                                <input className="form-control" type="datetime-local" name="tanggal" required />
                                            </div>
                                        </div>
                                        
                                        {isJenis == 1 && isBonus == 0 ?
                                        <div className="col-12">
                                            <div className="form-group">
                                                Bukti Transfer
                                                <input className="form-control" type="file" name="bukti_tf" required />
                                            </div>
                                        </div>:null}
                                        {isJenis == 0 && isPajakAdmin == 0 && isBonus == 0 ? 
                                        <div className="col-12">
                                            <div className="form-group">
                                                Payroll
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" name="payroll" checked={isPayroll} onChange={handlePayroll} />
                                                </div>
                                            </div>
                                        </div>:null}
                                        {isPayroll == 0 ? 
                                            <>
                                                {isJenis == 0 && isPajakAdmin == 0 && slcAnggaranName != 'Pajak' && slcAnggaranName != 'Biaya Administrasi' ?
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            Rekening Tujuan
                                                            <input className="form-control" type="number" name="rekening_tujuan" 
                                                                    value={rekTujuan} onChange={handleRekTujuan} required />
                                                                {predictTujuan.length > 0 ?
                                                                    <div className="form-select"> 
                                                                        {predictTujuan.map((item, index) => (
                                                                            <option key={item.id} value={item.nomor_rekening} onClick={handleOptTujuan}>
                                                                                {item.nomor_rekening} - {item.nama} ({item.kode_lembaga})
                                                                            </option>
                                                                        ))} 
                                                                    </div>:null}
                                                        </div>
                                                    </div>
                                                :null}

                                                <div className="col-12">
                                                    <div className="form-group">
                                                        Nominal
                                                        {showTooltip && <div className="text-danger">Nominal tidak boleh melebihi sisa anggaran <FormatRupiah value={anggaranSisa}/></div>}
                                                        <CurrencyInput 
                                                            name="nominal" className="form-control" onChange={handleChangeNominalInput}
                                                            prefix="Rp " value={nominalInput} decimalsLimit={4} required />
                                                    </div>
                                                </div>
                                            </>:null
                                        }

                                    </div>
                                    <br />
                                    <button className="btn btn-xs btn-secondary me-2" type="submit" onClick={() => setIsSelesai(false)}>
                                        <span className="material-icons md-18 me-1">save_as</span> Draft
                                    </button>
                                    <button className="btn btn-xs btn-success" type="submit" onClick={() => setIsSelesai(true)}>
                                        <span className="material-icons md-18 me-1">save</span> Selesai
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrxUnitForm