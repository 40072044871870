import Moment from "moment/moment";
import { Cookies } from 'react-cookie';
import { useEffect, useState } from "react";

import {FormatRupiah} from '@arismun/format-rupiah';
import CurrencyInput from "react-currency-input-field";
import { useNavigate, useParams, Link } from "react-router-dom";
import { swalCustom } from '../../../components/CustomStyle';
import { GetAllRekening, GetAnggaranList, GetRekeningList, GetTransaksiUnitDetail, UpdateTransaksiUnit } from "../../../services/KeuanganService";

const TrxUnitEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    let cookies = new Cookies();
    let homebase = cookies.get('homebase_kode');
    let homebase_rekening = cookies.get('homebase_rekening');

    let [kode, setKode] = useState([]);
    let [detail, setDetail] = useState({});
    let [isSelesai, setIsSelesai] = useState(false);
    let [isJenis, setIsJenis] = useState(0);

    let [rek, setRek] = useState([]);
    let [noRek, setNoRek] = useState();
    let [rekTujuan, setRekTujuan] = useState('');
    let [predictTujuan, setPredictTujuan] = useState([]);
    let [periodeNowYear, setPeriodeNowYear] = useState('');

    let [anggaranSisa, setAnggaranSisa] = useState(0);
    let [slcAnggaranName, setSlcAnggaranName] = useState('');
    let [nominalInput, setNominalInput] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);

    const INPUT_TIMEOUT = 250;

    const getPredictions = (value) => {
        const cari_kode = rek.filter(item => String(item.kode_lembaga).toLowerCase().indexOf(value.toLowerCase()) !== -1);
        const cari_nama = rek.filter(item => String(item.nama).toLowerCase().indexOf(value.toLowerCase()) !== -1);
        const cari_rek = rek.filter(item => String(item.nomor_rekening).toLowerCase().indexOf(value.toLowerCase()) !== -1);
        let hasil = []

        if (cari_kode.length > 0) {
            cari_kode.map(x => {
                if (hasil.includes(x) == false) {
                    hasil.push(x)
                }
            });
        }

        if (cari_nama.length > 0) {
            cari_nama.map(x => {
                if (hasil.includes(x) == false) {
                    hasil.push(x)
                }
            });
        }

        if (cari_rek.length > 0) {
            cari_rek.map(x => {
                if (hasil.includes(x) == false) {
                    hasil.push(x)
                }
            });
        }

        return hasil;
    }

    const handleRekTujuan = (event) => {
        const value = event.target.value;
        setRekTujuan(value);

        if (value.length > 0) {
            setTimeout(() => {
                const predictions = getPredictions(value);
                setPredictTujuan(predictions);
            }, INPUT_TIMEOUT);
        } else {
            setPredictTujuan([]);
        }
    }

    const handleOptTujuan = (event) => {
        setRekTujuan(event.target.value);
        setPredictTujuan([]);
    }

    const getRekening = async () => {
        await GetAllRekening().then(response => {
            setRek(response.data.rows);

        }).catch(() => console.log('error GetAllRekening'));
    }

    const getRekHomebase = async () => {
        await GetRekeningList(homebase).then((response) => {
            setNoRek(response.data.nomor_rekening);

        }).catch(() => console.log('error GetRekeningList'));
    }

    const getKode = async () => {
        await GetAnggaranList(homebase).then((response) => {

            const excludedAgrMasuk = ['Termin 1', 'Termin 2', 'Termin 3', 'Bagi Hasil atau Bonus'];
            const excludedAgrKeluar = ['Pajak', 'Biaya Administrasi'];

            let agr_list = response.data.rows;

            if (isJenis == 0){
                agr_list = response.data.rows.filter((vals) => !excludedAgrMasuk.includes(vals.nama_kegiatan));
            }else{
                agr_list = response.data.rows.filter((vals) => !excludedAgrKeluar.includes(vals.nama_kegiatan));
            }

            setKode(agr_list);


        }).catch(() => console.log('error GetAnggaranList'));
    }

    const getDetail = async () => {
        await GetTransaksiUnitDetail(homebase, id).then((response) => {
            setDetail(response.data);
            setNominalInput(response.data.nominal);
            setSlcAnggaranName(response.data.anggaran.nama_kegiatan);
            setIsJenis(response.data.status_trx);
            setRekTujuan(response.data.rekening_tujuan);
            setPeriodeNowYear(response.data.anggaran.periode.tahun);

        }).catch(() => console.log('error GetTransaksiUnitDetail'));
    }

    useEffect(() => {
        getKode();
        getDetail();
        getRekening();
        getRekHomebase();
    }, [id])

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('anggaran_id', event.target.anggaran.value);
        formData.append('keterangan', event.target.keterangan.value);
        formData.append('status_trx', detail.status_trx);

        let date_str = event.target.tanggal.value;
        let date_millis = new Date(date_str).getTime();

        formData.append('tanggal', date_millis);

        if (detail.status_trx == 1) {
            if (event.target.bukti.files[0] !== undefined) {
                formData.append('bukti', event.target.bukti.files[0]);
            }
        }

        formData.append('rekening_aktif', homebase_rekening);
        formData.append('rekening_asal', homebase_rekening);

        if (detail.is_payroll == 0) {
            if (detail.status_trx == 0) {
                let rek_pajak = 1111;
                let rek_biaya_admin = 2222;
                let rekz = 0;
                
                try{
                    rekz = event.target.rekening_tujuan.value;
                }catch{
                    rekz = 0;
                }

                if (slcAnggaranName == 'Pajak'){
                    rekz = rek_pajak;
                }else if (slcAnggaranName == 'Biaya Administrasi'){
                    rekz = rek_biaya_admin;
                }
                formData.append('rekening_tujuan', rekz);
                // formData.append('rekening_tujuan', event.target.rekening_tujuan.value);
            } else {
                formData.append('rekening_tujuan', homebase_rekening);
            }
            formData.append('nominal', event.target.nominal.value.replace(/[^0-9.]/g, ''));
        }

        formData.append('kode_lembaga', detail.kode_lembaga);
        formData.append('is_checker', true);
        formData.append('is_approve', false);
        formData.append('selesai', isSelesai);
        formData.append('is_payroll', detail.is_payroll);

        UpdateTransaksiUnit(homebase, id, formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil diubah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true) {
                    navigate('/trx-unit');
                }
            });
        }).catch(response => {
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal ditambah",
                type: "error",
            });
        });
    };

    const handleChangeNominalInput = (event) => {
        const input_val = event.target.value.replace(/[^0-9.]/g, '');
        console.log('ini adalah is jenis: ', isJenis);

        const excludeAgrName = ['Termin 1', 'Termin 2', 'Termin 3', 'Pajak', 'Biaya Administrasi', 'Bagi Hasil atau Bonus']

        if (!excludeAgrName.includes(slcAnggaranName)){

            if (isJenis == 0){
                if (input_val > anggaranSisa){
                    setShowTooltip(true);
                    setNominalInput(anggaranSisa);
                }else{
                    setShowTooltip(false);
                    setNominalInput(input_val);
                }
            }else{
                setShowTooltip(false);
                setNominalInput(input_val);
            }

        }else{
            console.log('220')
            setShowTooltip(false);
            setNominalInput(input_val);
        }
    }

    const handleChangeAnggaran = (event) => {
        const opt_label = event.target.selectedOptions[0].text;
        const opt_val = event.target.selectedOptions[0].value;

        const selected_agr = kode.find(kod => kod.id == opt_val);
        setAnggaranSisa(selected_agr.sisa_dana);
        setSlcAnggaranName(selected_agr.nama_kegiatan);

        setNominalInput(0);
        setShowTooltip(false);
        setDetail({ ...detail, anggaran: opt_val });
    }


    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Transaksi Unit Edit</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            {detail.no_checker == 1 && detail.selesai == 0 ?
                                <span className="badge badge-danger text-danger m-3">
                                    <b>{detail.note_checker}</b>
                                </span> : null
                            }
                            {detail.no_approve == 1 && detail.selesai == 0 ?
                                <span className="badge badge-danger text-danger m-3">
                                    <b>{detail.note_approve}</b>
                                </span> : null
                            }
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                Anggaran {periodeNowYear}
                                                <select className="form-control" name="anggaran" value={detail.anggaran && detail.anggaran.id}
                                                    onChange={handleChangeAnggaran}>
                                                    {kode.map((v, k) => {
                                                        return <option value={v.id}>{v.nama_kegiatan} (<FormatRupiah value={v.sisa_dana} />)</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Keterangan
                                                <input className="form-control" type="text" name="keterangan" required
                                                    value={detail.keterangan}
                                                    onChange={x => setDetail({ ...detail, keterangan: x.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Jenis
                                                {detail.status_trx == 0 ?
                                                    <input className="form-control" value="Keluar" readOnly /> :
                                                    <input className="form-control" value="Masuk" readOnly />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Tanggal
                                                <input className="form-control" type="datetime-local" name="tanggal" required
                                                    value={Moment(detail.tanggal).format("YYYY-MM-DDTHH:mm")}
                                                    onChange={x => setDetail({ ...detail, tanggal: x.target.value })} />
                                            </div>
                                        </div>

                                        {detail.status_trx == 1 ?
                                            <div className="col-12">
                                                <div className="form-group">
                                                    Bukti
                                                    <p>File saat ini: {detail.bukti_tf}</p>
                                                    <input className="form-control" type="file" name="bukti" />
                                                    <input className="form-control" type="text" name="nama_bukti" value={detail.bukti_tf} hidden />
                                                </div>
                                            </div> : null}

                                        {detail.is_payroll == 0 ?
                                            <>
                                                {detail.status_trx == 0 && slcAnggaranName != 'Pajak' && slcAnggaranName != 'Biaya Administrasi' ? 
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            Rekening Tujuan
                                                            {slcAnggaranName}
                                                            <input className="form-control" type="number" name="rekening_tujuan" required value={rekTujuan} onChange={handleRekTujuan} />
                                                            {predictTujuan.length > 0 ?
                                                                <div className="form-select">
                                                                    {predictTujuan.map((item, index) => (
                                                                        <option key={item.id} value={item.nomor_rekening} onClick={handleOptTujuan}>
                                                                            {item.nomor_rekening} - {item.nama} ({item.kode_lembaga})
                                                                        </option>
                                                                    ))}
                                                                </div> : null}
                                                        </div>
                                                    </div> : null}
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        Nominal
                                                        {showTooltip && <div className="text-danger">Nominal tidak boleh melebihi sisa anggaran <FormatRupiah value={anggaranSisa}/></div>}
                                                        <CurrencyInput
                                                            name="nominal"
                                                            className="form-control"
                                                            prefix="Rp "
                                                            defaultValue={nominalInput}
                                                            onChange={handleChangeNominalInput}
                                                            decimalsLimit={2}
                                                            required />
                                                    </div>
                                                </div>
                                            </>
                                            : null}
                                    </div>
                                    <br />
                                    <Link to="/trx-unit" className="btn btn-xs btn-secondary me-1">
                                        <span className="material-icons md-18 me-1">chevron_left</span>Kembali
                                    </Link>
                                    {detail.is_edit == 1 ?
                                        <button className="btn btn-xs btn-success" type="submit" onClick={() => setIsSelesai(true)}>
                                            <span className="material-icons md-18 me-1">save</span>Selesai
                                        </button>
                                        : <>
                                            <button className="btn btn-xs btn-secondary me-2" type="submit" onClick={() => setIsSelesai(false)}>
                                                <span className="material-icons md-18 me-1">save_as</span>Draft
                                            </button>
                                            <button className="btn btn-xs btn-success" type="submit" onClick={() => setIsSelesai(true)}>
                                                <span className="material-icons md-18 me-1">save</span>Selesai
                                            </button></>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrxUnitEdit