import {Cookies} from "react-cookie";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';
import {FormatRupiah} from "@arismun/format-rupiah";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {header, numTable, swalCustom} from '../../../components/CustomStyle';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {GetRekeningListV2, GetRekeningDelegasiListV2, DeleteRekeningDelegasiListV2} from "../../../services/KeuanganService";

const RekeningList = () => {
    const log_rek = '/log-rekening-unit';
    const {SearchBar} = Search;
    const navigate = useNavigate();

    let cookies = new Cookies();
    let username = cookies.get('username');
    let homebase_kode = cookies.get('homebase_kode');
    let homebase_rek = cookies.get('homebase_rekening');

    let [page, setPage] = useState(1);
    let [data, setData] = useState({});
    let [kepala, setKepala] = useState();
    let [delegasi, setDelegasi] = useState([]);

    const getData = async () => {
        await GetRekeningListV2(homebase_kode, homebase_rek).then((response) => {
            setData(response.data);
            setKepala(response.data.kepala);

        }).catch(() => console.log('error GetRekeningListV2'));
    }

    const getDataDelegasi = async () => {
        if (kepala !== undefined){
            if (kepala == username){
                await GetRekeningDelegasiListV2(homebase_kode, homebase_rek).then((response) => {
                    setDelegasi(response.data.rows);

                }).catch(() => console.log('error GetRekeningDelegasiListV2'));
            }
        }
    }

    useEffect(() => {
        setData([]);
        getData();
    }, [homebase_rek])

    useEffect(() => {
        getDataDelegasi();
    }, [data])

    const opensweetalert = (delegator) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin menghapus ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                DeleteRekeningDelegasiListV2(homebase_kode, delegator, homebase_rek).then(response => {
                    swalCustom.fire('Berhasil dihapus!', '', 'success')
                    getDataDelegasi();
                })
            }
        })
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Kode Lembaga',
        dataField: 'kode_lembaga',
        headerStyle: header
    }, {
        text: 'Kepala',
        dataField: 'kepala',
        headerStyle: header
    }, {
        text: 'Delegator',
        dataField: 'delegator',
        headerStyle: header
    }, {
        text: 'Action',
        dataField: 'button',
        formatter: (cell, row, rowIndex) => {
            return <>
                <button onClick={(e) => {e.stopPropagation(); opensweetalert(row.delegator)}} className='btn btn-xs btn-danger'>
                    {/* <span className="material-icons md-18">delete</span> */}
                    <span className="material-icons md-18">delete</span>
                </button>
            </>
        },
        headerStyle: header
    }];

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Rekening</h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-9 mb-2">
                                <div className="card bg-transparent shadow-xl">
                                    <div className="overflow-hidden position-relative border-radius-xl" style={{ backgroundImage: `url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/card-visa.jpg')`}}>
                                        <span className="mask bg-gradient-dark"></span>
                                            <div className="card-body position-relative z-index-1 p-3">
                                                <div className="ms-auto w-20 d-flex align-items-start justify-content-end">
                                                    {/* {data.kepala == username ?
                                                        <Link to="/rekening/edit" className="btn btn-xs btn-warning">
                                                            <i className="fa fa-edit fa-lg me-1"></i> Edit Rekening
                                                        </Link>:null
                                                    } */}
                                                </div>
                                                <p className="text-white text-bold text-sm opacity-8 mb-0">Nomor Rekening</p>
                                                <h5 className="text-white mt-4 mb-5 pb-2">{data.nomor_rekening}</h5>
                                                <div className="d-flex">
                                                    <div className="d-flex">
                                                        <div className="me-4">
                                                            <p className="text-white text-sm opacity-8 mb-0">Nama Lembaga</p>
                                                            <h6 className="text-white mb-0">{data.nama}</h6>
                                                            <br/>
                                                            <p className="text-white text-sm opacity-8 mb-0">Kode Lembaga</p>
                                                            <h6 className="text-white mb-0">{data.kode_lembaga}</h6>
                                                            <br/>
                                                            <p className="text-white text-sm opacity-8 mb-0">Kepala Lembaga</p>
                                                            <h6 className="text-white mb-0">{data.kepala}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto w-20 d-flex align-items-end justify-content-end">
                                                        <img className="w-60 mt-2" src="../assets/img/logos/mastercard.png" alt="logo"/>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="card">
                                    <div className="card-header mx-4 p-3 text-center">
                                        <h6 className="text-center mb-0">Saldo</h6>
                                    </div>
                                    <div className="card-body pt-0 p-3 text-center">
                                        <h5 className="mb-0">
                                            {data.saldo && <FormatRupiah value={data.saldo} />}
                                        </h5>
                                        <br/>
                                        <Link to={log_rek} className="btn btn-xs btn-primary me-2">
                                            <span className="material-icons md-18 me-1">notes</span> Log Rekening
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {data.kepala == username ?
            <div className="col-12 mt-4">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Daftar Delegator</h5>
                            </div>

                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto">
                                    {data.kepala == username ?
                                        <Link to="/rekening/delegator" className="btn btn-xs btn-success me-2">
                                            <span className="material-icons md-18 me-1">add_box</span> Tambah Delegator
                                            {/* <span className="material-icons md-18 me-1">add_box</span> Tambah Delegator */}
                                        </Link>:null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ToolkitProvider
                            keyField="created_at"
                            data={ delegasi }
                            columns={ columns }
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar { ...props.searchProps } />
                                        <br />
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="created_at"
                                            { ...props.baseProps }
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                            }})}
                                            bordered={ false }
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            headerWrapperClasses="thead-light"
                                            rowClasses="text-sm font-weight-normal"
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
            </div>:null}
        </div>
    );
}

export default RekeningList