import { useNavigate, useParams } from "react-router-dom";
import { GetDetailTrxUnitVerifikasi, GetTransaksiUnitDetail, UpdateTransaksiUnit, UpdateTrxUnitVerifikasiList } from "../../../services/KeuanganService";
import React, { useEffect, useState } from "react";
import Moment from "moment/moment";
import { Link } from 'react-router-dom';
import { detailStyle, header, swalCustom } from '../../../components/CustomStyle';
import { Cookies } from 'react-cookie';
import { FormatRupiah } from "@arismun/format-rupiah";
import ModalDialog from "../../../components/Modal";

const TrxUnitVerifikasiDetail = () => {
    const { id } = useParams();
    let navigate = useNavigate();
    let [detail, setDetail] = useState({});
    let [verifNota, setVerifNota] = useState();
    let [verifTf, setVerifTf] = useState();

    const getDetail = (id) => {
        GetDetailTrxUnitVerifikasi(id).then((response) => {
            setDetail(response.data);
            if (response.data.verif_bukti == 2) {
                setVerifNota(true);
            } else {
                setVerifNota(false);
            }

            if (response.data.verif_bukti_tf == 2) {
                setVerifTf(true);
            } else {
                setVerifTf(false);
            }

        }).catch(() => console.log('error get_trx_unit_detail'));
    }

    useEffect(() => {
        getDetail(id);
    }, [id])

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('anggaran_id', detail.anggaran.id);

        formData.append('verif_bukti', verifNota);
        formData.append('verif_bukti_tf', verifTf);
        formData.append('note_bukti', event.target.note_bukti.value);

        UpdateTrxUnitVerifikasiList(id, formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil diubah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true) {
                    navigate('/trx-unit-verifikasi/list');
                }
            });
        }).catch(response => {
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal ditambah",
                type: "error",
            });
        });
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Verifikasi Bukti Transaksi Unit</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                {detail.length !== 0 ?
                                    <>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Anggaran
                                            </div>
                                            <div className="col-md-5">
                                                {detail.anggaran && detail.anggaran.nama_kegiatan}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Keterangan
                                            </div>
                                            <div className="col-md-5">
                                                {detail.keterangan}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Total Anggaran
                                            </div>
                                            <div className="col-md-5">
                                                <b>{detail.anggaran && <FormatRupiah value={detail.anggaran.total_anggaran} />}</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Total Transaksi
                                            </div>
                                            <div className="col-md-5">
                                                <b><FormatRupiah value={detail.total_transaksi} /></b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Tanggal
                                            </div>
                                            <div className="col-md-5">
                                                {Moment(detail.tanggal).format('DD MMMM YYYY')}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Status
                                            </div>
                                            <div className="col-md-5">
                                                {detail.is_checker == true ?
                                                    <span className="badge badge-success text-success"> Checker </span> :
                                                    <span className="badge badge-danger text-danger"> Checker </span>} <br />
                                                {detail.is_approve == true ?
                                                    <span className="badge badge-success text-success"> Approval </span> :
                                                    <span className="badge badge-danger text-danger"> Approval </span>}
                                            </div>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                            <div className="row mt-3">
                                                <div className="col-md-2">
                                                    Bukti Nota
                                                </div>
                                                <div className="col-md-2">
                                                    <ModalDialog
                                                        titles={'Bukti Nota'}
                                                        datas={detail.bukti}
                                                        verifs={detail.verif_bukti} />
                                                </div>
                                                {detail.verif_bukti < 2 ?
                                                    <div className="col-md-2 form-check">
                                                        <input id="vbn" type="checkbox" name="verif_bukti_nota" className="form-check-input" onChange={e => { setVerifNota(!verifNota) }} />
                                                        <label for="vbn">Valid</label>
                                                    </div> : null}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    Bukti Transfer
                                                </div>
                                                <div className="col-md-2">
                                                    <ModalDialog
                                                        titles={'Bukti Transfer'}
                                                        datas={detail.bukti_tf}
                                                        verifs={detail.verif_bukti_tf} />
                                                </div>
                                                {detail.verif_bukti_tf < 2 ?
                                                    <div className="col-md-2 form-check">
                                                        <input id="vbt" type="checkbox" name="verif_bukti_tf" className="form-check-input" onChange={e => { setVerifTf(!verifTf) }} />
                                                        <label for="vbt">Valid</label>
                                                    </div> : null}
                                            </div>
                                            {detail.note_bukti != null && detail.note_bukti != '' ? <>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        Catatan sebelumnya
                                                    </div>
                                                    <div className="col-md-8">
                                                        {detail.note_bukti}
                                                    </div>
                                                </div><br /></> : null}
                                            <div className="row">
                                                <div className="col-md-2">
                                                    Catatan
                                                </div>
                                                <div className="col-md-2">
                                                    <textarea className="form-control" name="note_bukti"></textarea>
                                                </div>
                                            </div>
                                            <br />
                                            <Link to="/trx-unit" className="btn btn-sm btn-secondary me-2">
                                                <span className="material-icons md-18 me-1">chevron_left</span>Kembali
                                            </Link>
                                            <button className="btn btn-sm btn-success" type="submit">
                                                <span className="material-icons md-18 me-1">save</span>Selesai
                                            </button>
                                        </form>
                                    </> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrxUnitVerifikasiDetail