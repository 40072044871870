import { GetRabDetail } from "../../../services/KeuanganService";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { FormatRupiah } from '@arismun/format-rupiah';
import { header, numTable } from "../../../components/CustomStyle";
import moment from "moment";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

const RabDetail = () => {
    const { SearchBar } = Search;
    const { id } = useParams();

    let [data, setData] = useState({});
    let [detail, setDetail] = useState([]);
    let [page, setPage] = useState(1);


    useEffect(() => {
        GetRabDetail(id).then((response) => {
            setData(response.data);
            setDetail(response.data.detail);

        }).catch(() => console.log('error get_rab_detail'));
    }, [id])

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Rekening Tujuan',
        dataField: 'rekening_tujuan',
        formatter: (cell, row, rowIndex) => {
            return <>
                <b>{row.nama_rekening_tujuan}</b><br />
                <i>{row.rekening_tujuan}</i>
            </>
        },
        headerStyle: header
    }, {
        text: 'Nominal',
        dataField: 'nominal',
        formatter: (cell, row, rowIndex) => {
            return <b><FormatRupiah value={row.nominal} /></b>;
        },
        headerStyle: header
    }, {
        text: 'Tanggal',
        dataField: 'tanggal',
        formatter: (cell, row, rowIndex) => {
            return <>{moment(row.tanggal).format('DD MMMM YYYY hh:mm')}</>
        },
        headerStyle: header
    }];

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">RAB Detail</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            Nama <br />
                                            {data.nama}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            Nominal <br />
                                            {/* {data.nominal} */}
                                            <b><FormatRupiah value={data.nominal} /></b>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            Penggunaan <br />
                                            {/* {data.nominal} */}
                                            <b>{data.persentase_penggunaan} %</b>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            Tahun <br />
                                            {data.tahun}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            PIC <br />
                                            {data.pic}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <ToolkitProvider
                                    keyField="kode"
                                    data={detail}
                                    columns={columns}
                                    search>
                                    {
                                        props => (
                                            <div>
                                                <SearchBar {...props.searchProps} />
                                                <br />
                                                <BootstrapTable
                                                    keyField="id"
                                                    {...props.baseProps}
                                                    pagination={paginationFactory({
                                                        onPageChange: (page, sizePerPage) => {
                                                            setPage(page);
                                                        }
                                                    })}
                                                    bordered={false}
                                                    hover
                                                    wrapperClasses="table-responsive"
                                                    classes="table table-responsive"
                                                    rowClasses="text-sm font-weight-normal"
                                                />
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                                <br />
                                <Link to="/rab" className="btn btn-sm btn-secondary">
                                    <span className="material-icons md-18 me-1">chevron_left</span>Kembali
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RabDetail