import { Cookies } from "react-cookie";
import { useState, useEffect } from "react";

import { useNavigate, Link } from "react-router-dom";
import { swalCustom } from "../../../components/CustomStyle";
import { GetRekeningListV2, PostRekeningDelegasiListV2 } from "../../../services/KeuanganService";

const DelegasiForm = () => {
    const navigate = useNavigate();

    let cookies = new Cookies();
    let homebase = cookies.get('homebase_kode');
    let homebase_rekening = cookies.get('homebase_rekening');

    let [detail, setDetail] = useState({});

    const getDetail = () => {
        GetRekeningListV2(homebase, homebase_rekening).then((response) => {
            setDetail(response.data);

        }).catch(() => console.log('error get_rekening_unit'));
    }

    useEffect(() => {
        getDetail();
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('kode_lembaga', event.target.kode_lembaga.value);
        formData.append('nomor_rekening', event.target.rekening.value);
        formData.append('kepala', event.target.kepala.value);
        formData.append('delegator', event.target.delegator.value);

        PostRekeningDelegasiListV2(homebase, homebase_rekening, formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil ditambah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true) {
                    navigate('/rekening');
                }
            });
        }).catch(response => {
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal ditambah",
                type: "error",
            });
        });
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Tambah Delegator</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                Kode Lembaga
                                                <input className="form-control" type="text" value={homebase} name="kode_lembaga" readOnly />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Nomor Rekening
                                                <input className="form-control" type="text" value={homebase_rekening} name="rekening" readOnly />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Kepala
                                                <input className="form-control" type="text" name="kepala" value={detail.kepala} readOnly />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Delegator Username
                                                <input className="form-control" type="text" name="delegator" required />
                                            </div>
                                        </div>

                                    </div>
                                    <br />
                                    <Link to="/rekening" className="btn btn-xs btn-secondary me-1">
                                        <span className="material-icons md-18 me-1">chevron_left</span>Kembali
                                    </Link>
                                    <button className="btn btn-xs btn-success" type="submit">
                                        <span className="material-icons md-18 me-1">save</span>Simpan
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DelegasiForm