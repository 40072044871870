import {Link} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import {header, numTable} from '../../../components/CustomStyle';
import {GetUserGroupList} from "../../../services/KeuanganService";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

const UserGroupList = () => {
    const { SearchBar } = Search;

    let [page, setPage] = useState(1);
    let [data, setData] = useState([]);

    const getData = async () => {
        await GetUserGroupList().then((response) => {
            setData(response.data.rows);

        }).catch(() => console.log('error GetUserGroupList'));
    }

    useEffect(() => {
        getData();
    }, [])

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Username',
        dataField: 'username',
        headerStyle: header
    }, {
        text: 'First Name',
        dataField: 'first_name',
        headerStyle: header
    }, {
        text: 'Last Name',
        dataField: 'last_name',
        headerStyle: header
    }, {
        text: 'Email',
        dataField: 'email',
        headerStyle: header
    }, {
        text: 'Group',
        dataField: 'groups.name',
        formatter: (cell, row, rowIndex) => {
            return <>
                {row.groups.map((val, key) => 
                    <><i className="fa fa-circle fa-sm me-1"></i>{val.name}<br /></>
                )}
            </>
        },
        headerStyle: header
    }];

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">User Group</h5>
                            </div>

                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto">
                                    <Link to="/user-group/add" className="btn btn-xs btn-success me-2">
                                        <span className="material-icons md-18 me-1">add_box</span> Tambah
                                    </Link>
                                    <Link to="/user-group/delete" className="btn btn-xs btn-danger">
                                        <i className="fa fa-trash fa-lg me-1"></i> Hapus
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ToolkitProvider
                            keyField="id"
                            data={ data }
                            columns={ columns }
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar { ...props.searchProps } />
                                        <br />
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            { ...props.baseProps }
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                            }})}
                                            bordered={ false }
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            headerWrapperClasses="thead-light"
                                            rowClasses="text-sm font-weight-normal"
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserGroupList