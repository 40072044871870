import {Cookies} from "react-cookie";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {swalCustom} from "../../../components/CustomStyle";
import {DeleteUserGroup, GetUserGroupList} from "../../../services/KeuanganService";

const UserGroupDelete = () => {
    const cookies = new Cookies();

    const navigate = useNavigate();
    let [data, setData] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
        let username = event.target.username.value;
        let group = event.target.group.value;

        DeleteUserGroup(username, group).then(() => {
            cookies.set('is_group', username+group+2);

            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil dihapus",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true){
                    navigate('/user-group');
                }
            });
        }).catch(() => {
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal ditambah",
                type: "error",
            });
        });
    };

    const getData = () => {
        GetUserGroupList().then((response) => {
            setData(response.data.rows);

        }).catch(() => console.log('error get user group'));
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">User Group Delete</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                Username
                                                <select className="form-select" name="username">
                                                    {data.map((val, key) => {
                                                        return <option value={val.username}>{val.username}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Group
                                                <select className="form-select" name="group">
                                                    <option value="Keuangan" selected>Keuangan</option>
                                                    <option value="Pimpinan">Pimpinan</option>
                                                    <option value="Checker">Checker</option>
                                                    <option value="Approve">Approval</option>
                                                    <option value="Unit">Unit</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <button className="btn btn-xs btn-success" type="submit">
                                        <span className="material-icons md-18 me-1">save</span>Simpan
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserGroupDelete