import Swal from 'sweetalert2';

export const header = {
    textTransform: 'uppercase', fontSize: 10, fontWeight: 'bold',
    color: '#adb5bd'
}

export const headerCenter = {
    textTransform: 'uppercase', fontSize: 10, fontWeight: 'bold',
    color: '#adb5bd', textAlign: 'center'
}

export const tede = {
    fontWeight: 'normal', text: 'small'
}

export const headerStickLeft = {
    textTransform: 'uppercase', fontSize: 10, fontWeight: 'bold', 
    color: '#adb5bd', position: 'sticky', left: 0, 
    backgroundColor: 'white', minWidth: 350
}

export const tdStickLeft = {
    position: "sticky", left: 0, backgroundColor: "white",
    whiteSpace: "normal", minWidth: 350, 
    boxShadow: 'inset 0 1px 0 white, inset 0 2px 0 white'
}

export const tdStickLeftUpper = {
    position: "sticky", left: 0, backgroundColor: "white",
    whiteSpace: "normal", minWidth: 350, 
    boxShadow: 'inset 0 1px 0 white, inset 0 2px 0 white',
    textTransform: 'upppercase'
}

export const tdStickLeftChild = {
    position: "sticky", left: 0, backgroundColor: "#f6f9fc",
    whiteSpace: "normal", minWidth: 350, 
}

export const tdBaru = {
    position: 'sticky', top: 0, left: 0, right: 0,
    padding: '4px 3px', backgroundColor: '#fffff',
    boxShadow: 'inset 0 1px 0 white, inset 0 2px 0 white'
}

export const detailStyle = {
    fontWeight: 'bold', fontSize: 14, color: '#8b99a7'
}

export const wsNormal = {
    whiteSpace: 'normal',
}

export const swalCustom = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-xs btn-success me-2',
      cancelButton: 'btn btn-xs btn-danger',
    },
    buttonsStyling: false
})

export function numTable(page, rowIndex) {
    if (page === 1){
        return rowIndex + 1;
    }else{
        return (page * 10 - 10) + (rowIndex + 1);
    }
}

export function rangez(start, end) {
    return Array.from(Array(end||start).keys()).slice(!!end*start)
}
