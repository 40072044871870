import { Link, useParams, useNavigate } from "react-router-dom";
import { GetMataAnggaranDetail } from "../../../services/KeuanganService";
import { useEffect, useState } from "react";
import Moment from "moment/moment";
import { FormatRupiah } from "@arismun/format-rupiah";
import { detailStyle } from "../../../components/CustomStyle";
import ModalDialog from "../../../components/Modal";

const TrxMataAnggaranDetail = () => {
    const { id } = useParams();
    let [detail, setDetail] = useState({});
    let navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    const getDetail = () => {
        GetMataAnggaranDetail(id).then((response) => {
            setDetail(response.data);

        }).catch(() => console.log('error get_mata_anggaran_detail'));
    }

    useEffect(() => {
        getDetail();
    }, [id])

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Transaksi Mata Anggaran Detail</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            {detail.no_checker == 1 && detail.selesai == 0 ?
                                <span className="badge badge-danger text-danger m-3">
                                    <b>{detail.note_checker}</b>
                                </span> : null
                            }
                            {detail.no_approve == 1 && detail.selesai == 0 ?
                                <span className="badge badge-danger text-danger m-3">
                                    <b>{detail.note_approve}</b>
                                </span> : null
                            }
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-9">
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Mata Anggaran
                                            </div>
                                            <div className="col-md-10">
                                                <b>{detail.mata_anggaran}</b>
                                            </div>
                                        </div>

                                        {detail.is_rab == true ?
                                            <div className="row mb-1">
                                                <div className="col-md-2" style={detailStyle}>
                                                    RAB
                                                </div>
                                                <div className="col-md-10">
                                                    <b>{detail.rab}</b>
                                                </div>
                                            </div> : null}

                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Keterangan
                                            </div>
                                            <div className="col-md-10">
                                                {detail.keterangan}
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Jenis
                                            </div>
                                            <div className="col-md-10">
                                                {detail.jenis_trx}
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Tanggal
                                            </div>
                                            <div className="col-md-10">
                                                {Moment(detail.tanggal).format("DD MMMM YYYY HH:mm")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="row">
                                            {detail.is_checker == false ?
                                                <span className="badge badge-danger text-danger mb-2"> Checker </span> :
                                                <span className="badge badge-success text-success mb-2"> Checker </span>}

                                            {detail.is_approve == false ?
                                                <span className="badge btn-xs badge-danger text-danger"> Approval </span> :
                                                <span className="badge btn-xs badge-success text-success"> Approval </span>}

                                            {/* {detail.bukti != null ?
                                                <Link target='_blank' className="btn btn-xs btn-outline-danger mt-2" to={detail.bukti}>
                                                    Lihat Bukti
                                                </Link>:'-'} */}

                                            <span className="mt-2"></span>

                                            {detail.bukti_pengajuan != null ?
                                                <ModalDialog
                                                    titles={'Bukti Pengajuan'}
                                                    datas={detail.bukti_pengajuan}
                                                    verifs={detail.verif_bukti} /> : '-'}

                                            {detail.bukti != null ?
                                                <ModalDialog
                                                    titles={'Bukti'}
                                                    datas={detail.bukti}
                                                    verifs={detail.verif_bukti} /> : '-'}
                                        </div>
                                    </div>
                                    <hr className="mt-4 mb-4" />
                                    <div className="row mb-1">
                                        {detail.jenis_trx == 'Keluar' ?
                                            detail.rekening_asal != '' && detail.rekening_asal != null ? <>
                                                <div className="col-md-4">
                                                    <span style={detailStyle}>Rekening Asal</span> <br />
                                                    {detail.nama_rekening_asal} <br />
                                                    <b>{detail.rekening_asal}</b><br />
                                                    <b><FormatRupiah value={detail.saldo_rekening_asal} /></b>
                                                </div>
                                                <div className="col-md-2">
                                                    <br />
                                                    <i className="fa fa-arrow-circle-right fa-xl text-success"></i>
                                                </div></> : null : null}
                                        <div className="col-md-4">
                                            <span style={detailStyle}>Rekening Tujuan</span> <br />
                                            {detail.nama_rekening_tujuan} <br />
                                            <b>{detail.rekening_tujuan}</b><br />
                                            <b><FormatRupiah value={detail.saldo_rekening_tujuan} /></b>
                                        </div>
                                        <div className="col-md-2">
                                            <span style={detailStyle}>Nominal</span> <br />
                                            <b><FormatRupiah value={detail.nominal} /></b>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <button onClick={goBack} className="btn btn-sm btn-secondary">
                                    <span className="material-icons md-18 me-1">chevron_left</span>Kembali
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrxMataAnggaranDetail