import {useState} from "react";
import {Cookies} from 'react-cookie';
import {useNavigate} from "react-router-dom";

import CurrencyInput from "react-currency-input-field";
import {swalCustom} from '../../../components/CustomStyle';
import {PostAnggaran} from "../../../services/KeuanganService";

const AnggaranForm = () => {
    const navigate = useNavigate();
    
    let cookies = new Cookies();
    let homebase = cookies.get('homebase_kode');
    let username = cookies.get('username');

    let [jenis, setJenis] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('jenis_anggaran', event.target.jenis.value);
        formData.append('nama_kegiatan', event.target.kegiatan.value);
        formData.append('total_anggaran', event.target.total_anggaran.value.replace(/[^0-9.]/g, ''));
        formData.append('kode_lembaga', homebase);
        formData.append('created_by', username);

        if (jenis == "Non Apple"){
            formData.append('bukti_pengajuan', event.target.bukti.files[0]);
        }

        PostAnggaran(homebase, formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil ditambah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true){
                    navigate('/anggaran');
                }
            });
        }).catch(err => swalCustom.fire(err.response.data.message, '', 'fail'));
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Anggaran Form</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                Jenis Anggaran
                                                <select className="form-control" name="jenis" onChange={(x) => setJenis(x.target.value)}>
                                                    <option value="Apple" selected>Apple</option>
                                                    <option value="Non Apple">Non Apple</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Nama Kegiatan
                                                <input className="form-control" type="text" name="kegiatan" required />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Total Anggaran
                                                <CurrencyInput name="total_anggaran" className="form-control" prefix="Rp " decimalsLimit={2} required />
                                            </div>
                                        </div>
                                        {jenis == "Non Apple" ?
                                        <div className="col-12">
                                            <div className="form-group">
                                                Bukti Pengajuan
                                                <input className="form-control" type="file" name="bukti" required />
                                            </div>
                                        </div>:null}
                                    </div>
                                    <br />
                                    <button className="btn btn-xs btn-success" type="submit">
                                        <span className="material-icons md-18 me-1">save</span>Simpan
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnggaranForm