import Moment from "moment/moment";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import ModalDialog from "../../components/Modal";

import { FormatRupiah } from "@arismun/format-rupiah";
import { Link, useNavigate, useParams } from "react-router-dom";
import { detailStyle, swalCustom } from "../../components/CustomStyle";
import { GetMataAnggaranDetail, TolakApproveMataAnggaran, UpdateApproveMataAnggaran } from "../../services/KeuanganService";

const TrxMataAnggaranApproveDetail = () => {
    const { id } = useParams();
    let navigate = useNavigate();

    let [detail, setDetail] = useState({});

    const [isShow, invokeModal] = useState(false)
    const initModal = (row) => {
        return invokeModal(!isShow)
    }

    const getDetail = () => {
        GetMataAnggaranDetail(id).then((response) => {
            setDetail(response.data);

        }).catch(() => console.log('error get_mata_anggaran_detail'));
    }

    const approvesweetalert = (id) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin approve ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                UpdateApproveMataAnggaran(id).then(response => {
                    swalCustom.fire('Berhasil diapprove!', '', 'success')
                    getDetail();
                }).catch(err => {
                    swalCustom.fire('Gagal diapprove!'.response.data.message, 'error')
                    getDetail();
                });
            }
        })
    }

    useEffect(() => {
        getDetail();
    }, [id])

    const handleTolak = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('note', event.target.note.value);

        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin menolak ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                TolakApproveMataAnggaran(event.target.id.value, formData).then(response => {
                    invokeModal(!isShow)
                    swalCustom.fire('Transaksi ditolak!', '', 'success')
                    navigate('/mata-anggaran-checked')

                }).catch(err => {
                    invokeModal(!isShow)
                    swalCustom.fire('Gagal ditolak!'.response.data.message, 'error')
                    navigate('/mata-anggaran-checked')

                });
            } else {
                invokeModal(!isShow)
            }
        })
    }

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Transaksi Mata Anggaran Detail</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <Modal show={isShow}>
                                <Modal.Header>
                                    <Modal.Title>Tolak Transaksi Unit</Modal.Title>
                                </Modal.Header>
                                <form onSubmit={handleTolak}>
                                    <Modal.Body>
                                        Kode Lembaga: {detail.kode_lembaga} <br />
                                        Keterangan: {detail.keterangan} <br />
                                        Total Transaksi: <FormatRupiah value={detail.nominal} />
                                        <br />
                                        Catatan
                                        <textarea className='form-control' type='text' name='note' required />
                                        <input name='id' value={detail.id} hidden />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type='button' className='btn btn-xs btn-primary' onClick={initModal}>Batal</button>
                                        <button type='submit' className='btn btn-xs btn-danger'>Tolak</button>
                                    </Modal.Footer>
                                </form>
                            </Modal>

                            <div className="card-body">
                                <div className="row">
                                    <div className="col-9">
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Mata Anggaran
                                            </div>
                                            <div className="col-md-10">
                                                <b>{detail.mata_anggaran}</b>
                                            </div>
                                        </div>

                                        {detail.is_rab == true ?
                                            <div className="row mb-1">
                                                <div className="col-md-2" style={detailStyle}>
                                                    RAB
                                                </div>
                                                <div className="col-md-10">
                                                    <b>{detail.rab}</b>
                                                </div>
                                            </div> : null}
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Keterangan
                                            </div>
                                            <div className="col-md-10">
                                                {detail.keterangan}
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Jenis
                                            </div>
                                            <div className="col-md-10">
                                                {detail.jenis_trx == '1' ? 'Keluar' : 'Masuk'}
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Tanggal
                                            </div>
                                            <div className="col-md-10">
                                                {Moment(detail.tanggal).format("DD MMMM YYYY")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="row">
                                            {detail.is_checker == false ?
                                                <span className="badge badge-danger text-danger mb-2"> Checker </span> :
                                                <span className="badge badge-success text-success mb-2"> Checker </span>}

                                            {detail.is_approve == false ?
                                                <span className="badge btn-xs badge-danger text-danger"> Approval </span> :
                                                <span className="badge btn-xs badge-success text-success"> Approval </span>}

                                            <span className="mt-2"></span>

                                            {detail.bukti_pengajuan != null ?
                                                <ModalDialog
                                                    titles={'Bukti Pengajuan'}
                                                    datas={detail.bukti_pengajuan}
                                                    verifs={detail.verif_bukti} /> : '-'}
                                            <br />
                                            {detail.bukti != null ?
                                                <ModalDialog
                                                    titles={'Bukti'}
                                                    datas={detail.bukti}
                                                    verifs={detail.verif_bukti} /> : '-'}
                                        </div>
                                    </div>
                                    <hr className="mt-4 mb-4" />
                                    <div className="row mb-1">
                                        {detail.rekening_asal != '' && detail.rekening_asal != null ? <>
                                            <div className="col-md-4">
                                                <span style={detailStyle}>Rekening Asal</span> <br />
                                                {detail.nama_rekening_asal} <br />
                                                <b>{detail.rekening_asal}</b><br />
                                                <b><FormatRupiah value={detail.saldo_rekening_asal} /></b>
                                            </div>
                                            <div className="col-md-2">
                                                <br />
                                                <i className="fa fa-arrow-circle-right fa-xl text-success"></i>
                                            </div></> : null}
                                        <div className="col-md-4">
                                            <span style={detailStyle}>Rekening Tujuan</span> <br />
                                            {detail.nama_rekening_tujuan} <br />
                                            <b>{detail.rekening_tujuan}</b><br />
                                            <b><FormatRupiah value={detail.saldo_rekening_tujuan} /></b>
                                        </div>
                                        <div className="col-md-2">
                                            <span style={detailStyle}>Nominal</span> <br />
                                            <b><FormatRupiah value={detail.nominal} /></b>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <Link to="/mata-anggaran-checked" className="btn btn-sm btn-secondary me-2">
                                    <span className="material-icons md-18 me-1">chevron_left</span>Kembali
                                </Link>
                                {detail.is_approve == 0 ? <>
                                    <button onClick={(e) => { e.stopPropagation(); approvesweetalert(id) }} className='btn btn-sm btn-info me-1'>
                                        <i className="far fa-thumbs-up fa-lg text-white me-2"></i> Approve
                                    </button>

                                    <button onClick={(e) => { e.stopPropagation(); initModal() }} className='btn btn-sm btn-danger'>
                                        <i className="fas fa-times fa-lg text-white me-1"></i> Tolak
                                    </button></> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrxMataAnggaranApproveDetail